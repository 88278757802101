import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";
import Competency from './components/competency/Competency';
import JobRole from './components/jobrole/JobRole';
import CompetencySkills from './components/competencySkills/CompetencySkills';
import Header from './components/navigation/Header';
import Jobrolecompetency from './components/jobrolecompetency/Jobrolecompetency';
import Showquestions from './components/question/questionPreview/Showquestions';
import CreateAssessment from './components/assessment/CreateAssessment';
import Exam from './components/question/questionAttemp/Exam';
import SaveExamData from './components/question/questionAttemp/SaveExamData';
import PageNotFound from './components/reusableComp/PageNotfound';
import ScoreSummerySheet from './components/question/questionAttemp/ScoreSummerySheet';
import Signup from './components/authentication/Signup';
import Login from './components/authentication/Login';
import ProtectedRoute from './components/reusableComp/ProtectedRoute';
import Unauthorised from './components/reusableComp/Unauthorised';
import ManageUser from './components/Manage/ManageUsers';
import ManageUserType from './components/Manage/ManageUserType';
import Role from './components/Manage/Roles';
import Permissions from './components/Manage/Permissions';
import Menus from './components/Manage/Menus';
import Status from './components/Manage/Status';
import ManageSkills from './components/Manage/ManageSkills'
import Home from './components/Home/Home'
import ProfileFilter from './components/assessment/newfeatures/ProfileFilter';
import AssesmentResults from './components/assessment/newfeatures/AssessmentResults';
import ShowCharts from './components/assessment/newfeatures/ChartView/ShowCharts';
import EditAssessment from './components/assessment/EditAssessment';
import CreateQuestion from './components/question/CreateQuestion';
import ManageQuestions from './components/question/ManageQuestions';
import EditQuestion from './components/question/EditQuestion';
import ManageAssessments from './components/assessment/ManageAssessments';
import ManageRoleMenus from './components/Manage/ManageRoleMenus/ManageRoleMenus';
import ManageRolePermission from './components/Manage/ManageRolePermissions/ManageRolePermission';
import ManageUserRoles from './components/Manage/ManageUserRole/ManageUserRole';
import Organisations from './components/Manage/ManageOrganisation/Organisations';
import Allotment from './components/allocations/Allotment';
import AuthorDash from './components/Dashboard/AuthorDash';
import CandidateDash from './components/Dashboard/CandidateDash';
import AssessmentSeries from './components/assessment/assessmentSeries/AssessmentSeries';
import CandidateGroup from './components/CandidateGroup/CandidateGroup';
import CandidateAssessmentUi from './components/assessment/CandidateAssessmentUi';
import Chennel from './components/Channel/Channel';
import MyChannel from './components/MyChannel/MyChannel';
import ExploreChannels from './components/ExploreChannels/ExploreChannels';
import Invite from './components/Invitation/Invite';
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar';
import { MenuOpen } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import GroupMenu from './components/Manage/ManageMenuGroup/GroupMenu';
import { ListItemText } from '@mui/material';
import CompanyPeople from './components/Manage/ManageCompanyPeople/CompanyPeople';
import SignUpForCompany from './components/authentication/SignupForCompany';

function App() {

  const [RoutesData, setRoutesData] = useState([])
  const [collapsed, setCollapsed] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);
  const location = useLocation();
  const isAuthPage = location.pathname == '/login' || location.pathname == '/signUp' || location.pathname == '/SignupforCompany';
  const isHomePage = location.pathname === '/'
  const isUnknownPage = location.pathname === '/404';
  const getRoutes = async () => {
    console.log('atfuntion')
    console.log(isHomePage || localStorage.getItem('userId'))
    const result = await fetch(`${process.env.REACT_APP_API_URL}/getMenuitems/?userId=${localStorage.getItem('userId')}`, {
      method: 'get',
      headers: { 'Content-Type': 'application/json' }
    })
    const response = await result.json()
    if (result.ok) {
      setRoutesData(response.data)
    }

  }
  // close the sidebar on mobile screen 
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 576) { // Check for mobile screen size
        setToggled(false); // Close the sidebar
        setCollapsed(false)
      } else {
        setToggled(true); // Optionally, open the sidebar on larger screens
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check screen size initially

    return () => window.removeEventListener('resize', handleResize); // Cleanup
  }, []);

  useEffect(() => {
    if (localStorage.getItem('userId'))
      getRoutes()
  }, [])

  useEffect(() => {
    if (localStorage.getItem('userId')) {
      console.log('here');
      getRoutes();
    } else {
      console.log('User is logged out and on home page');
      let Routes = [
        { RoutePath: '', Menu: 'Home' },
        { RoutePath: 'login', Menu: 'Login' },
        { RoutePath: 'sign-up', Menu: 'SignUp' },
        { RoutePath: 'myChannel', Menu: 'Subscriptions' },
        { RoutePath: '#', Menu: 'Trending' }
      ];

      setRoutesData(Routes);
    }
  }, [location]);

  console.log(toggled)
  return (
    <div className=' overflow-hidden '>
      <div className="flex h-screen   overflow-hidden">
        {/* Sidebar */}
        {!isAuthPage && !isUnknownPage && <Sidebar onBackdropClick={() => { setToggled(false) }} toggled={toggled} breakPoint="sm" collapsed={collapsed} className="h-full bg-white w-64 fixed z-10 overflow-y-auto ">
          <Menu>
            <MenuItem onClick={() => setCollapsed(!collapsed)} className='py-3'>


              {collapsed ? <MenuIcon /> : <MenuOpen />}


            </MenuItem>

            {RoutesData?.map((parent, index) => (
              // Check if the parent has children
              parent.children && parent.children.length > 0 ? (
                <SubMenu key={index} label={parent.Menu}>
                  {parent.children.map((childMenu) => (
                    <MenuItem
                      key={childMenu.MenuId}
                      component={<Link to={`/${childMenu.RoutePath}`} />}
                      sx={{ pl: 4 }}
                    >
                      <ListItemText primary={childMenu.Menu} />
                    </MenuItem>
                  ))}
                </SubMenu>
              ) : (
                <MenuItem key={index} component={<Link to={`/${parent.RoutePath}`} />}>
                  <ListItemText primary={parent.Menu} />
                </MenuItem>
              )
            ))}
          </Menu>

        </Sidebar>}

        {/* Main Section */}
        <section className="flex flex-col flex-1  relative z-10 overflow-y-auto">
          {/* Header */}
          <Header setToggled={setToggled} toggled={toggled} />

          {/* Main Routes */}
          <main className="p-0">
            <Routes>
              <Route path="" element={<Home />} />
              <Route path="manageskills" element={<ProtectedRoute element={ManageSkills} />} />
              <Route path="managecompetency" element={<ProtectedRoute element={Competency} />} />
              <Route path="managejobrole" element={<ProtectedRoute element={JobRole} />} />
              <Route path="comp_skills" element={<ProtectedRoute element={CompetencySkills} />} />
              <Route path="jobrole_competency" element={<ProtectedRoute element={Jobrolecompetency} />} />
              <Route path="createQuestion" element={<ProtectedRoute element={CreateQuestion} />} />
              <Route path="previewQuestion" element={<ProtectedRoute element={Showquestions} />} />
              <Route path="createAsssessment" element={<ProtectedRoute element={CreateAssessment} />} />
              <Route path="exam" element={<ProtectedRoute element={Exam} />} />
              <Route path="ManageAssessments" element={<ProtectedRoute element={ManageAssessments} />} />
              <Route path="assessment" element={<ProtectedRoute element={SaveExamData} />} />
              <Route path="ScoreSummery" element={<ProtectedRoute element={ScoreSummerySheet} />} />
              <Route path="SignUp" element={<Signup />} />
              <Route path="/signUp/:email/:afterAuth/:Type/:Id" element={<Signup />} />
              <Route path="/SignupforCompany/:Email/:CompanyId/" element={<SignUpForCompany />} />
              <Route path="Login" element={<Login />} />
              <Route path="Login/:email/:afterAuth/:Type/:Id" element={<Login />} />
              <Route path="unauthorise" element={<Unauthorised />} />
              <Route path="manageuserType" element={<ProtectedRoute element={ManageUserType} />} />
              <Route path="manageRoles" element={<ProtectedRoute element={Role} />} />
              <Route path="managePermissions" element={<ProtectedRoute element={Permissions} />} />
              <Route path="manageMenus" element={<ProtectedRoute element={Menus} />} />
              <Route path="manageStatus" element={<ProtectedRoute element={Status} />} />
              <Route path="manageUsers" element={<ProtectedRoute element={ManageUser} />} />
              <Route path="profileFilter" element={<ProtectedRoute element={ProfileFilter} />} />
              <Route path="assesmentResults" element={<ProtectedRoute element={AssesmentResults} />} />
              <Route path="AssessmentReport" element={<ProtectedRoute element={ShowCharts} />} />
              <Route path="EditAssessment" element={<ProtectedRoute element={EditAssessment} />} />
              <Route path="manageQuestions" element={<ProtectedRoute element={ManageQuestions} />} />
              <Route path="editQuestion" element={<ProtectedRoute element={EditQuestion} />} />
              <Route path="*" element={<PageNotFound />} />
              <Route path="ManageRoleMenu" element={<ProtectedRoute element={ManageRoleMenus} />} />
              <Route path="ManageRolePermission" element={<ProtectedRoute element={ManageRolePermission} />} />
              <Route path="UserRoles" element={<ProtectedRoute element={ManageUserRoles} />} />
              <Route path="Organisations" element={<ProtectedRoute element={Organisations} />} />
              <Route path="Allocation" element={<ProtectedRoute element={Allotment} />} />
              <Route path="AuthorDashboard" element={<ProtectedRoute element={AuthorDash} />} />
              <Route path="CandidateDashboard" element={<ProtectedRoute element={CandidateDash} />} />
              <Route path="assessmentSeries" element={<ProtectedRoute element={AssessmentSeries} />} />
              <Route path="CandidateGroup" element={<ProtectedRoute element={CandidateGroup} />} />
              <Route path="CandidateAssessment" element={<ProtectedRoute element={CandidateAssessmentUi} />} />
              <Route path="MangeChannel" element={<ProtectedRoute element={Chennel} />} />
              <Route path="myChannel" element={<ProtectedRoute element={MyChannel} />} />
              <Route path="ExploreChannels" element={<ProtectedRoute element={ExploreChannels} />} />
              <Route path="email-invitation" element={<ProtectedRoute element={Invite} />} />
              <Route path="GroupMenu" element={<ProtectedRoute element={GroupMenu} />} />
              <Route path="CompanyUsers" element={<ProtectedRoute element={CompanyPeople} />} />

            </Routes>
          </main>
        </section>
      </div>
    </div >

  );
}

export default App;
