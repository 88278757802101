import React, { useEffect, useState } from 'react'
import StanderdConstants from '../../services/StanderdConstants';
import { InputBox } from '../../ButtonsIconsInputs/CustomInputs';
import ChannelPreview from '../assessment/ChannelPreview';
import { Box, Tab, Tabs } from '@mui/material';
import { AddIconButton, CustomButton, EditIconButton, LikeButton } from '../../ButtonsIconsInputs/IconButtons';
import PropTypes from 'prop-types';
import GenericChannelCard from '../Channel/GenericChannelCard';
import EditChannelModal from '../Channel/EditChannelModal';
import DeleteConfirmation from '../reusableComp/Deletecomfirmation';
import { checkIfUserBelongsToOrganization } from '../reusableComp/IsOrganisation';
import AddChannelModal from '../Channel/AddChannel';
import { filterActionsByPermission, hasPermission } from '../reusableComp/PermissionCheck';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};




function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



function MyChannel() {
    const [ChannelMasterData, setChannelMasterData] = useState([])
    const [error, setError] = useState(null);
    const [ShowMyCompanyTab, setShowMyCompanyTab] = useState(false)
    const [ShowChannelContent, setShowChannelContent] = useState(false)
    const [ChannelContent, setChannelContent] = useState([])
    const [ChannelData, setChannelData] = useState()
    const [value, setValue] = React.useState(0);
    const [filteredData, setfilteredData] = useState([]);
    const [query, setQuery] = useState('');
    const [editStatus, setEditStatus] = useState(false);
    const [insertionStatus, setInsertionStatus] = useState(false);

    const [open, setopen] = useState(false);
    const [currentDatarow, setcurrentDatarow] = useState(null);
    const [editOpen, setEditOpen] = useState(false);
    const handleopen = () => { setopen(true); }
    const handleclose = () => {
        setopen(false);
    }
    const handleEditClose = () => { setEditOpen(false); setcurrentDatarow(null); console.log("called") }

    const [ChannelType, setChannelType] = useState(StanderdConstants.MYCHANNELS)


    const handleChange = (event, newValue) => {
        setShowChannelContent(false)
        setValue(newValue);
        if (newValue === 0) {
            setChannelType(StanderdConstants.MYCHANNELS)
        }
        else {
            setChannelType(StanderdConstants.MYCOMPANYCHANNELS)
        }
    };

    const permissionsNameForThisPage = {
        Add: 'Channel-Add',
        Edit: 'Channel-Edit',
        Delete: 'Channel-Delete',
        LIKE: 'Channel-Like'

    }

    useEffect(() => {
        if (ChannelType) {
            getdata()
        }
    }, [ChannelType])

    useEffect(() => {
        getdata()

    }, [editStatus, setInsertionStatus])

    const getdata = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/Channel/myChannels`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: StanderdConstants.UserId, ChannelType })
            });
            const response = await result.json();
            setChannelMasterData(response.data);
            setfilteredData(response.data)

        } catch (error) {
            setError(error.message);
        }
    };
    const handleEditOpen = (Data, index) => {
        console.log(Data)
        setcurrentDatarow(Data); setEditOpen(true);

    }

    const handleDelete = async (item) => {

        console.log(item);

        const res = await fetch(`${process.env.REACT_APP_API_URL}/Channel/Delete/?ChannelId=${item}`, { // Correctly pass the ID in the URL path
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        });

        if (res.ok) {
            getdata()// Trigger success action
        } else {
            console.log('Error occurred while deleting');
        }
    };




    const actions = [
        {
            label: StanderdConstants.EDIT_LABEL,
            handler: handleEditOpen,
            tooltip: StanderdConstants.EDIT_LABEL,
            ButtonComponent: EditIconButton,
            requiredPermission: permissionsNameForThisPage.Edit,

        },
        {
            label: StanderdConstants.DELETE_LABEL,
            handler: handleDelete,
            tooltip: StanderdConstants.DELETE_LABEL,
            ButtonComponent: DeleteConfirmation,
            requiredPermission: permissionsNameForThisPage.Delete,


        },
        {
            label: 'like',
            handler: () => { },
            tooltip: 'like',
            ButtonComponent: LikeButton,
            requiredPermission: permissionsNameForThisPage.LIKE,

        }


    ];

    const AllowedActions = filterActionsByPermission(actions)
    console.log(AllowedActions)

    const handleSearch = (e) => {
        console.log(query);
        console.log(e.target.value)
        const searchQuery = e.target.value.toLowerCase();
        setQuery(searchQuery);
        if (!searchQuery) {
            setfilteredData(ChannelMasterData);
        } else {
            const result = ChannelMasterData.filter(row =>
                Object.values(row) // Get all values from the object (row)
                    .some(value =>
                        value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
                    )
            );
            console.log(result)
            setfilteredData(result);
        }
    }


    const GetChannelContent = async (ChannelId) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessment_get/ChannelContent`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ChannelId })
            });
            const response = await result.json();
            setChannelContent(response.data);
            setChannelData(response.channelData)
            setShowChannelContent(true)

        } catch (error) {
            setError(error.message);
        }
    }

    const renderChannelCards = (Data, VisibilityMode) => (

        <div>
            {
                ShowChannelContent && (
                    <>
                        <div className="col-span-full mb-4 m-2">
                            <button
                                onClick={() => setShowChannelContent(false)}
                                className="inline-flex items-center px-4 py-2  bg-blue-500 text-white font-semibold text-sm rounded-md border-none shadow hover:bg-blue-600 transition duration-200"
                            >
                                ← Back
                            </button>
                        </div>
                        <ChannelPreview Data={ChannelContent} channelData={ChannelData} />

                    </>


                )
            }
            {!ShowChannelContent && <div>
                <div className="flex flex-row  justify-between space-x-2 my-2 ">
                    <div className="flex items-center space-x-2 my-3">
                        <InputBox
                            inputId='searchChannel'
                            inputLabel="Search Channel"
                            inputValueValue={query}
                            onChange={handleSearch}
                            className="border p-4 rounded-lg"
                            type="text"
                        />

                    </div>
                    {<div className='my-2'>
                        {hasPermission(permissionsNameForThisPage.Add) && < AddIconButton action={() => handleopen(true)} className='mt-4' id={"addChannel"} tooltip='create new channel' />
                        }                        {open && <AddChannelModal handleClose={handleclose} setStatus={setInsertionStatus} />}

                    </div>}

                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 ">
                    {Data.map((item, index) => {
                        let badgeColor = "";
                        let borderColor = "";

                        badgeColor = "bg-purple-500";
                        borderColor = "border-purple-300";

                        return (
                            <GenericChannelCard
                                title={item.ChannelName}
                                subtitle={item.CreaterName || ''}
                                imageSrc={`${process.env.REACT_APP_API_URL}/AssessmentImages/${item.ChannelLogo}`}
                                badgeText="Channel"
                                badgeColor={badgeColor}
                                actions={AllowedActions}
                                item={item}

                                ShowChannelContent={() => GetChannelContent(item.ChannelId)}
                            />


                        );
                    })}


                </div>
            </div>}

            {editOpen && (
                <EditChannelModal
                    ChannelId={currentDatarow}

                    handleClose={handleEditClose}
                    setStatus={setEditStatus} />
            )}
        </div>
    );
    checkIfUserBelongsToOrganization().then(res => {
        setShowMyCompanyTab(res)

    })




    return (

        <div>
            <Box sx={{ width: '100%' }}>

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="My Channels" {...a11yProps(0)} />
                        {ShowMyCompanyTab && <Tab label="My Company Channels" {...a11yProps(1)} />}
                        {ShowMyCompanyTab}
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    {/* Call the function to render assessment cards */}
                    {renderChannelCards(filteredData, 'Myself')}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    {/* Call the function to render series cards */}
                    {renderChannelCards(filteredData, 'MyCompany')}
                </CustomTabPanel>
            </Box>

        </div>
    )
}

export default MyChannel