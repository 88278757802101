import { AcUnitOutlined, Add, AddCircleOutlineRounded, AssessmentOutlined, Close, Delete, Edit, InsertChart, NotificationsActiveRounded, NotificationsNoneRounded, RemoveRedEyeOutlined, SearchRounded, ThumbUpSharp } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { useState } from "react";

export function DeleteIconButton({ action, id = '1', tooltip = 'delete' }) {
    return (
        <IconButton onClick={action} id={`delete-${id}`} className="text-bg-red-800 mr-2" title={tooltip}>
            <Delete className='text-red-800' />
        </IconButton>

    )
}


export function EditIconButton({ action, id = '1', tooltip = 'Edit' }) {
    return (
        <IconButton
            onClick={action}
            id={`Edit-${id}`}
            className="border-none p-1 rounded-lg mr-2 "
            title={tooltip}

        >
            <Edit className='text-blue-900' />
        </IconButton>
    )
}

export function SearchIconButton({ action = () => { }, id = '1', tooltip = 'search' }) {
    return (
        <IconButton onClick={action} id={`search-${id}`} title={tooltip}>
            <SearchRounded />
        </IconButton>
    );
}

export function AddIconButton({ action = () => { }, id = '', tooltip = 'add' }) {
    return (
        <div>
            <button onClick={action} id={`Add-${id}`} className='bg-blue-500  border-none  text-white p-2 rounded-lg' title={tooltip}>
                <Add />
            </button>
        </div>
    )
}

export function ViewIconButton({ action = () => { }, id = '1', tooltip = 'view' }) {
    return (
        <div>
            <IconButton onClick={action} id={`View-${id}`} className='' title={tooltip}  >
                <RemoveRedEyeOutlined className="text-gray-400" />
            </IconButton>
        </div>
    )
}

export function TakeExamIconButtom({ action = () => { }, id = '1', tooltip = 'exam' }) {
    return (
        <div>
            <IconButton onClick={action} id={`exam-${id}`} className='' title={tooltip}  >
                <AssessmentOutlined className="text-gray-400" />
            </IconButton>
        </div>
    )
}

export function NormalmuiContainedButtom({ action = () => { }, id = '1', tooltip = 'exam', label = 'click here' }) {
    return (
        <div>
            <Button variant="contained" onClick={action} id={`exam-${id}`} className='' title={tooltip}  >
                {label}
            </Button>
        </div>
    )
}


export function ManageIconButton({ action = () => { }, id = '1', tooltip = 'manage' }) {
    return (
        <div>
            <IconButton onClick={action} id={`manage-${id}`} className='' title={tooltip}  >
                <AcUnitOutlined />
            </IconButton>
        </div>
    )
}

export function CloseIconButton({ action = () => { }, id = '1', tooltip = 'close', iconColor = 'text-gray-50' }) {
    return (

        <IconButton onClick={action} id={`close-${id}`} title={tooltip}  >
            <Close className={iconColor} />
        </IconButton>

    )
}


export function ChartIConButton({ action = () => { }, id = '1', tooltip = 'view' }) {
    return (
        <div>
            <IconButton onClick={action} id={`View-${id}`} className='' title={tooltip}  >
                <InsertChart className="text-gray-400" />
            </IconButton>
        </div>
    )
}

export const CustomButton = ({ Text, onClick, customcss, variant = 'contained', size = 'medium', disabled = false }) => {
    const baseStyles = 'flex items-center justify-center font-semibold transition duration-200 ease-in-out';
    const sizeStyles = {
        small: 'px-2 py-1 text-sm',
        medium: 'px-4 py-2 text-base',
        large: 'px-6 py-3 text-lg',
    };

    const variantStyles = {
        contained: 'bg-sky-600 text-white hover:bg-sky-700 active:bg-sky-800 disabled:bg-sky-400 border-none',
        outlined: 'border border-sky-600 text-sky-600 hover:bg-sky-600 hover:text-white active:bg-sky-700 disabled:border-sky-400 disabled:text-sky-400 disabled:bg-transparent',
        text: 'text-sky-600 hover:bg-sky-100 active:bg-sky-200 disabled:text-sky-400',
    };

    const combinedStyles = `rounded-md   ${baseStyles} ${sizeStyles[size]} ${variantStyles[variant]}  ${customcss}  ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`;

    return (
        <button className={combinedStyles} onClick={onClick} disabled={disabled}>
            {Text}
        </button>
    );
};


export function LikeButton({ action = () => { }, id = '1', tooltip = 'like' }) {
    return (
        <div>
            <IconButton onClick={action} id={`View-${id}`} className='' title={tooltip}  >
                <ThumbUpSharp className="text-gray-400" />
            </IconButton>
        </div>
    )
}



export function SubscribeButton({ action = () => { }, id = '1' }) {
    const [subscribed, setSubscribed] = useState(false); // State to track subscription status

    const handleClick = () => {
        setSubscribed(prevSubscribed => !prevSubscribed); // Toggle subscription status
        action(); // Call the action provided
    };

    return (
        <button
            onClick={handleClick}
            id={`subscribe-${id}`}
            className={`flex items-center px-4 py-2 rounded-lg transition duration-200 
                ${subscribed ? 'bg-blue-600 text-white' : 'bg-white text-blue-600 border border-blue-600'}
                hover:bg-blue-600 hover:text-white focus:outline-none`}
        >
            {subscribed ? (
                <span className="material-icons"><NotificationsActiveRounded /></span> // Icon for subscribed
            ) : (
                <span className="material-icons"><AddCircleOutlineRounded /></span> // Icon for subscribe
            )}
            <span className="ml-2">{subscribed ? 'Subscribed' : 'Subscribe'}</span>
        </button>
    );
}
