export const StanderdConstants = {
    StanderdConst: process.env.REACT_APP_API_URL,
    UserId: localStorage.getItem('userId'),
    USERS_TABLE_NAME: 'users',
    EDIT_LABEL: 'EDIT',
    DELETE_LABEL: 'DELETE',
    // allotment of assessment to student page
    ASSESSMENT_TABLE_COLUMN_ASSESSMENTNAME: 'nameOfAssessment',
    ASSESSMENT_TABLEHEADER: 'Assessment',
    StanderdConstants: 'Name',
    NAMEOFSTUDENT_TABLEHEADER: 'Candidate',
    //ASSESSMENT SERIES PAGE
    ASSESSMENTSERIESTABLE_COLOUMN_SERIESNAME: 'AssessmentSeriesName',
    SERIES_HEADER_NAME: 'Series Name',
    ASSESSMENT_BASISS_COMPETENCY: 'competency',
    ASSESSMENT_BASISS_JOBROLE: 'jobrole',
    ASSESSMENT_BASISS_SKILLS: 'skills',
    //new feture managemodule
    VIEW_REPORT_LABEL: 'View report',
    VIEW_RESULT_LABEL: 'View result',
    //on manageAssessment page , FROM ASSESSMENT TBALE
    ASSESSMENT_BASIS_DBTABLE_COLUMN: 'assesmentBasis',
    ASSESSMENT_TIME_DBTABLE_COLUMN: 'Time',
    ASSESSMENT_NUMBEROFQUESTION_DBTABLE_COLUMN: 'numberOfQuestions',
    ASSESSMENT_DIFFICULTYLEVEL_DBTABLE_COLUMN: 'difficultyLevel',
    HEADER_ASSESSMENTNAME: 'Name',
    HEADER_ASSESSMENTBASIS: 'Assessment Basis',
    HEADER_TIME: 'Time (minutes)',
    HEADER_NUMBEROFQUESTION: 'Number of Questions',
    HEADER_DIFFICULTYLEVEL: 'Difficulty level',
    //ON MANAGE ASSESSMENT PAGE
    LABEL_PREVIEWASSESSMENT: 'Preview Assessment',
    LABEL_TAKE_EXAM: 'TAKE EXAM',
    //ON COMPETENCY PAGE
    HEADER_COMPETENCY_NAME: 'Name',
    HEADER_COMPETENCY_DESCRIPTION: 'Description',
    DBTABLE_COLUMN_COMPETENCY_NAME: 'competencyName',
    DBTABLE_COLUMN_COMPETENCY_DESCRIPTION: 'competencyDescription',
    LABEL_MANAGE_SKILLS: 'manage skills',
    HEADER_JOBROLE_NAME: 'Job Role Name',
    HEADER_JOBROLE_DESCRIPTION: 'Job Role Description',
    DBTABLE_COLUMN_JOBROLE_NAME: 'jobroleName',
    DBTABLE_COLUMN_JOBROLE_DESCRIPTION: 'jobroleDescription',
    // on manage competency
    LABEL_MANAGE_COMPETENCY: 'Manage Competency',
    //on organisation/company page
    HEADER_COMPANY_NAME: 'Company Name',
    HEADER_COMPANY_TYPE: 'Company Type',
    DBTABLE_COLUMN_COMPANY_NAME: 'CompanyName',
    DBTABLE_COLUMN_COMPANY_TYPE: 'CompanyType',
    //ON MANGEREUSABLE ADD MODAL
    TABLENAME_STATIC_USERTYPE: 'staticusertypes',
    COLUMN_USERTYPEID: 'UserTypeId',
    COLUMN_STATUS: 'Status',
    TABLENAME_STAITCSTATUS: 'staticstatus',
    TABLENAME_STATICMENUS: 'staticmenus',
    TABLENAME_STATICROLES: 'staticroles',
    TABLENAME_SKILLS: 'skills',
    TABLENAME_USERS: 'users',
    //ON PAGE MANAGE USERS
    COLUMN_DBTABLE_USER_NAME: 'Name',
    COLUMN_DBTABLE_USER_EMAIL: 'Email',
    COLUMN_DBTABLE_USER_PHONE: 'Phone',
    COLUMN_DBTABLE_USER_TYPE: 'UserType',
    COLUMN_DBTABLE_USER_STATUS: 'Status',
    HEADER_USER_TYPE: 'User Type',
    //on page manage menus
    COLUMN_DBTABLE_MENU: 'Menu',
    COLUMN_DBTABLE_DESCRIPTION: 'Description',
    COLUMN_DBTABLE_ROUTE_PATH: 'RoutePath',
    COLUMN_DBTABLE_STATUS: 'Status',
    HEADER_ROUTEPATH: 'Route Path',
    // on page manage Permissions
    COLUMN_DBTABLE_PERMISSION: 'Permission',
    TABLENAME_STATICPERMISSIONS: 'staticpermission',
    // on page Roles
    HEADER_ROLE: 'Role',
    HEADER_IS_RESERVED: 'is Reserved',
    HEADER_STATUS: 'Status',
    COLUMN_DBTABLE_ROLE: 'Role',
    COLUMN_DBTABLE_IS_RESERVED: 'isReserved',
    MCQ: 'mcq',
    MCQSINGLE: 'single',
    MCQMULTIPLE: 'multiple',
    FREEAnswers: 'free',
    ASSESSMENTTYPESET: 'set',
    //on Candidate Batch
    HEADER_CANDIDATEBATCH: 'Batch',
    HEADER_CBC: 'Candidate',
    COLUMN_DBTABLE_CANDIDATEBATCH: 'BatchName',
    COLUMN_DBTABLE_CBC: 'Candidate',
    ASSIGNMENTTYPEASSESSMENT: 'assessment',
    ASSIGNMENTTYPESERIES: 'series',
    ASSIGNMENTTYPECHANNEL: 'channel',
    MYCHANNELS: 'mychannels',
    MYCOMPANYCHANNELS: 'mycompanychannles',
    EASY_DIFFICULTY: 'easy',
    NORAML_DIFFICULTY: 'normal',
    HARD_DIFFICULTY: 'hard',
    INVITECANDIDATEFORASSESSMENT: 1,
    INVITE_COMPANY_EMPLOYEES: 6,












































}

export default StanderdConstants
