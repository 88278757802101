import React, { useEffect, useState } from 'react'
import { CustomSelect, CustomSelectv2, CustomSelectv3, InputBox } from '../../ButtonsIconsInputs/CustomInputs'
import ScrollableTable from '../reusableComp/ScrollableTable';
import TableComp from '../reusableComp/TableComp';
import DataTable from './DataTable';
import { AddIconButton, EditIconButton } from '../../ButtonsIconsInputs/IconButtons';
import DeleteConfirmation from '../reusableComp/Deletecomfirmation';
import EditModal from './EditModal';
import AddModal from './AddModal';
import { useNavigate } from 'react-router-dom';
import Heading from '../reusableComp/Heading';
import './App.css'
import StanderdConstants from '../../services/StanderdConstants';

function Allotment() {
    const [Data, SetData] = useState([]);
    const [open, setopen] = useState(false);
    const [insertionStatus, setInsertionStatus] = useState(false);
    const [editStatus, setEditStatus] = useState(false);
    const [filteredData, setfilteredData] = useState([]);
    const [editOpen, setEditOpen] = useState(false);
    const [currentDatarow, setcurrentDatarow] = useState(null);
    const api_url = process.env.REACT_APP_API_URL
    const navigate = useNavigate()
    const [query, setQuery] = useState('');


    useEffect(() => {
        if (insertionStatus) {
            getAssessmentUsersData()
            setInsertionStatus(false);
        }
    }, [insertionStatus, Data]);


    const handleopen = () => { setopen(true); }
    const handleclose = () => { setopen(false); }
    const handleEditOpen = (Data, index) => {
        console.log(Data)
        setcurrentDatarow(Data); setEditOpen(true);

    }
    const handleEditClose = () => { setEditOpen(false); setcurrentDatarow(null); console.log("called") }

    const handleDelete = async (item) => {
        let Id = Object.values(item)[0]
        console.log(Id)
        console.log(item)

        const res = await fetch(`${api_url}/assessmentallocation/Delete/`, {
            method: 'post',
            headers: { 'Content-Type': "application/json" },
            body: JSON.stringify({ Type: item.Type, SeriesId: item.SeriesId, assessmentId: item.assessmentId })
        });
        if (res.ok) {
            console.log("deleted successfully");
            setInsertionStatus(true);
        } else {
            console.log("error occurred");
        }
    }






    const AssessmentActions = [
        {
            label: StanderdConstants.EDIT_LABEL,
            handler: handleEditOpen,
            tooltip: StanderdConstants.EDIT_LABEL,
            ButtonComponent: EditIconButton,
        },
        {
            label: StanderdConstants.DELETE_LABEL,
            handler: handleDelete,
            tooltip: StanderdConstants.DELETE_LABEL,
            ButtonComponent: DeleteConfirmation,

        }

    ];




    const getAssessmentUsersData = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessmentallocation/getData`, {
                method: 'get',
                headers: { 'Content-Type': 'application/json' },

            });
            const response = await result.json();
            if (result.ok) {
                SetData(response.data)
                setfilteredData(response.data)
            }

        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        getAssessmentUsersData();
    }, []);



    useEffect(() => {
        if (editStatus) {
            getAssessmentUsersData()
            setEditStatus(false)
        }
    }, [editStatus]);

    const columns = [
        { header: StanderdConstants.ASSESSMENT_TABLEHEADER, field: StanderdConstants.ASSESSMENT_TABLE_COLUMN_ASSESSMENTNAME },
        { header: StanderdConstants.NAMEOFSTUDENT_TABLEHEADER, field: StanderdConstants.StanderdConstants }
    ];

    const handleSearch = (e) => {
        console.log(query);
        console.log(e.target.value)
        const searchQuery = e.target.value.toLowerCase();
        setQuery(searchQuery);
        if (!searchQuery) {
            setfilteredData(Data);
        } else {
            const result = Data.filter(row =>
                Object.values(row) // Get all values from the object (row)
                    .some(value =>
                        value?.toString().toLowerCase().includes(searchQuery.toLowerCase()) // Check if any value contains the search query
                    )
            );
            console.log(result)
            setfilteredData(result);
        }
    }

    return (
        <div className="container mx-auto py-8 px-2">
            <Heading heading={" Assign Assessment to Candidate"} />
            <div className="flex flex-row  justify-between space-x-2 my-2 ">
                <div className="flex items-center space-x-2">
                    <InputBox
                        inputId='search'
                        inputLabel="Search Assessment"
                        inputValueValue={query}
                        onChange={handleSearch}
                        className="border p-2 rounded-lg"
                        type="text"
                    />

                </div>
                {<div>
                    <AddIconButton action={handleopen} id={"addAssignment"} tooltip='Add new record' />
                    {open && <AddModal handleclose={handleclose} setStatus={setInsertionStatus} />}

                </div>}

            </div>
            <DataTable Data={filteredData} columns={columns} actions={AssessmentActions} />




            {editOpen && (
                <EditModal
                    Data={currentDatarow}
                    handleClose={handleEditClose}
                    setStatus={setEditStatus} />
            )}
        </div>

    )

}

export default Allotment