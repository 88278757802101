import React from 'react'
import ExploreChannels from '../ExploreChannels/ExploreChannels'

function Home() {
    const pathname = window.location.pathname;
    console.log('Pathname:', pathname);
    return (
        <div className=''>
            <ExploreChannels />
        </div>
    )
}

export default Home
