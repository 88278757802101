import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';

import { Link, useNavigate } from 'react-router-dom';
import { Explore, Home, PermIdentity, PowerSettingsNewOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import ExploreChannels from '../ExploreChannels/ExploreChannels';

const drawerWidth = 240;

const pathname = window.location.pathname;
console.log('Pathname:', pathname);

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => ({
        flexGrow: 1,
        // padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        variants: [
            {
                props: ({ open }) => open,
                style: {
                    transition: theme.transitions.create('margin', {
                        easing: theme.transitions.easing.easeOut,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                    marginLeft: 0,
                },
            },
        ],
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    variants: [
        {
            props: ({ open }) => open,
            style: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: `${drawerWidth}px`,
                transition: theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
        },
    ],
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    // padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function NavigatorDrawer({ element: Element }) {
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const navigate = useNavigate()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [RoutesData, setRoutesData] = useState([])
    const pathname = window.location.pathname;
    console.log('Pathname:', pathname);
    React.useEffect(() => {
        if (isSmallScreen) {
            setOpen(false);
        }
    }, [isSmallScreen]);


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };






    const logout = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('userId')
        navigate('/')
        let Routes = [
            { RoutePath: '', Menu: 'Home' },
            { RoutePath: 'myChannel', Menu: 'Subscriptions' },
            { RoutePath: '#', Menu: 'Trending' }
        ];

        setRoutesData(Routes);
    }


    const getRoutes = async () => {
        console.log('atfuntion')

        const result = await fetch(`${process.env.REACT_APP_API_URL}/getMenuitems/?userId=${localStorage.getItem('userId')}`, {
            method: 'get',
            headers: { 'Content-Type': 'application/json' }
        })
        const response = await result.json()
        if (result.ok) {
            setRoutesData(response.data)
        }

    }




    useEffect(() => {
        if (pathname !== '/' || localStorage.getItem('token')) {
            console.log('here')
            getRoutes();
        } else {
            console.log('else')
            let Routes = [
                { RoutePath: '', Menu: 'Home' },
                { RoutePath: 'myChannel', Menu: 'Subscriptions' },
                { RoutePath: '#', Menu: 'Trending' }
            ];

            setRoutesData(Routes);
        }
    }, []);


    return (

        <Box sx={{ display: 'flex' }}>

            <CssBaseline />
            <AppBar className='h-0' open={open}>
                <Toolbar className='border-b-2  flex justify-between bg-sky-600'  >

                    <IconButton

                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={[
                            {
                                mr: 2,

                            },
                            open && { visibility: 'hidden' },
                        ]}
                    >
                        <MenuIcon className='text-gray-700' />
                    </IconButton>

                    <div className='self-center'>
                        <h1 className='text-2xl text-gray-50 text-center font-bold tracking-wider p-2'>Wise Assessment</h1>

                    </div>

                    <div className='m-0'>
                        {pathname !== '/' || localStorage.getItem('token') ? (<IconButton className='border-none' onClick={logout} title='logout'><PowerSettingsNewOutlined className='text-gray-50 font-extralight' /></IconButton>
                        ) : (<div className=' border p-1 px-2 rounded-3xl'>    <PermIdentity /><span onClick={() => navigate('/signup')} className='honver:underline hover:cursor-pointer mx-1'>SignUp</span>/<span onClick={() => navigate('/login')} className='honver:underline hover:cursor-pointer mx-1'>SignIn</span>  </div>)
                        }
                    </div>

                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader >
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>

                <List>
                    <Box sx={{ width: 250 }} role="presentation" >

                        <List>
                            {RoutesData?.map((item, index) => (
                                <ListItem key={index} disablePadding className='hover:bg-slate-100'>
                                    <Link className='p-2 ml-3 ' to={`/${item.RoutePath}`} >{item.Menu}</Link>
                                </ListItem>
                            ))}
                        </List>
                        <Divider />
                    </Box >
                </List>

            </Drawer>
            <Main open={open}>
                <DrawerHeader />

                {pathname == '/' ? (<ExploreChannels />) : (<Element />)}

            </Main>
        </Box>
    );
}
