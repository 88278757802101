import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, ListItemText, Menu, Typography } from '@mui/material';
import { EditIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import { ExpandCircleDown } from '@mui/icons-material';

const DataTable = ({ actions, data }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const rowsPerPage = 10;
    const [page, setPage] = useState(1);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const paginatedData = data?.slice(startIndex, endIndex);



    useEffect(() => {
        const totalPages = Math.ceil(data.length / rowsPerPage);
        if (page > totalPages && totalPages > 0) {
            setPage(totalPages);
        } else if (data.length === 0) {
            setPage(1);
        }
    }, [data, page, rowsPerPage]);



    return (
        <div className="overflow-x-auto">
            {paginatedData?.length > 0 ? (
                <>
                    <table className="w-full border-collapse border border-gray-200">
                        <thead>
                            <tr className="text-gray-50 bg-sky-600">
                                <th className="border p-2 text-start w-full">Menu Name</th>
                                <th className="border p-2 w-fit">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData.map((item, index) => (
                                <tr key={index} className="hover:bg-gray-50 border">
                                    {/* Render Parent Menu with Children in an Accordion */}
                                    <td colSpan={1}>
                                        {item.children && item.children.length > 0 ? (
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandCircleDown />}
                                                    aria-controls={`panel-${index}-content`}
                                                    id={`panel-${index}-header`}
                                                >
                                                    <Typography>#{item.SequenceNumber} {item.Menu}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <List component="div" disablePadding>
                                                        {item.children.map(childMenu => (
                                                            <ListItem
                                                                key={childMenu.MenuId}


                                                                sx={{ pl: 4 }}
                                                            >
                                                                <Typography className='pl-4'>#{childMenu.SequenceNumber} {childMenu.Menu}</Typography>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </AccordionDetails>
                                            </Accordion>
                                        ) : (
                                            <div className="flex items-center">
                                                <Typography className='pl-4'>#{item.SequenceNumber} {item.Menu}</Typography>
                                            </div>
                                        )}
                                    </td>

                                    {/* Render Actions if Available */}
                                    {actions?.length > 0 && (
                                        <td className="border p-2 w-fit">
                                            <div className="flex justify-around">
                                                {actions.map((action, actionIndex) => {
                                                    const ButtonComponent = action.ButtonComponent;
                                                    return (
                                                        <ButtonComponent
                                                            key={actionIndex}
                                                            action={() => action.handler(item, index)}
                                                            tooltip={action.tooltip}
                                                            id={index}
                                                            itemName={item.Menu} // Using the Menu name directly for clarity
                                                            itemData={item}
                                                            label={action.label}
                                                            className={action.className}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <div className="flex justify-center items-center m-auto p-6 bg-gray-100 text-gray-500 rounded-lg shadow-md">
                    <p className="text-lg font-semibold">No Data Found</p>
                </div>
            )}

            {/* Pagination Controls */}
            <div className="flex justify-center mt-4">
                <Stack spacing={2}>
                    <Pagination
                        count={Math.ceil(data.length / rowsPerPage)}
                        page={page}
                        onChange={handleChange}
                        color="primary"
                    />
                </Stack>
            </div>
        </div>


    );
};

export default DataTable;
