import React from 'react'
import StanderdConstants from '../../services/StanderdConstants'

export async function checkIfUserBelongsToOrganization() {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/companies/IsUserBelongsToOrganisation/?Id=${StanderdConstants.UserId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

        if (!res.ok) {
            // Handle non-200 status codes
            return false;
        }

        const result = await res.json();
        console.log(result)

        if (result && result.company) {
            return result.company;
        } else {
            return false;
        }

    } catch (error) {
        // Handle network or unexpected errors
        console.error('Error fetching organization data:', error);
        return false;
    }
}


export async function getAccessOptions() {
    const isOrganizationMember = await checkIfUserBelongsToOrganization();
    console.log(isOrganizationMember)
    const accessOptions = [
        { value: "private_me", label: "Private - Only to Me" },
        { value: "public", label: "Public - Everyone" },
    ];

    // Conditionally add the organization option if the user belongs to an organization
    if (isOrganizationMember) {
        accessOptions.splice(1, 0, { value: "private_organization", label: `Private - Only to ${isOrganizationMember}` });
    }

    return accessOptions;
}

