import React, { useEffect, useState } from 'react';
import { InputWithDropDownv2 } from '../../ButtonsIconsInputs/CustomInputs'; // Assuming you're using this for multi-select input
import { CloseIconButton } from '../../ButtonsIconsInputs/IconButtons';
import Heading from '../reusableComp/Heading';
import { Button } from '@mui/material';
import { useFormik } from 'formik';
import StanderdConstants from '../../services/StanderdConstants';

function EditModal({ handleClose, setStatus, Data }) {
    const [CandidateGroupData, setCandidateGroupData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [message, setMessage] = useState([]);

    const formik = useFormik({
        initialValues: {
            Id: '',
            Type: '',
            usersIdArray: [],
            groupIdArray: [],
            selectedCandidatedata: [],
        },
        onSubmit: (values) => {
            console.log(values);
            updateData(values);
        }
    });
    console.log(Data)
    // Update formik values based on the Data received
    useEffect(() => {
        if (Data) {
            const Idarray = Data?.users?.map(user => user?.Id) || [];
            const Groupidarray = Data?.groups?.map(group => group?.CandidateGroupId) || [];
            formik.setValues({
                Id: Data.SeriesId || Data.assessmentId,
                Type: Data.Type,
                usersIdArray: Idarray,
                groupIdArray: Groupidarray,
                selectedCandidatedata: [
                    ...Idarray.map(id => `individual-${id}`), // Add prefix for individual IDs
                    ...Groupidarray.map(id => `group-${id}`) // Add prefix for group IDs
                ],
            });
        }
    }, [Data]);
    console.log(formik.values)
    const updateData = async (data) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessmentallocation/Edit`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            });
            const response = await result.json();
            if (result.ok) {
                setMessage(response.message)
                setStatus(true);
            }
        } catch (error) {
            setMessage(error.message);
        }
    };

    const getCandidateGroupData = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/CandidateGroup/getGroupData`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: StanderdConstants.UserId })
            });
            const response = await result.json();
            if (result.ok) {
                setCandidateGroupData(response.data);
            }
        } catch (error) {
            setMessage(error.message);
        }
    };

    const getUsers = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/manage/getData`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ tablename: StanderdConstants.TABLENAME_USERS })
            });
            const response = await result.json();
            if (result.ok) {
                setUsersData(response.data);
            }
        } catch (error) {
            setMessage(error.message);
        }
    };

    useEffect(() => {
        getCandidateGroupData();
        getUsers();
    }, []);

    // Prepare combined candidate list for dropdown
    const combinedCandidateList = [
        ...(Array.isArray(usersData) ? usersData.map(user => ({
            id: `individual-${user.Id}`, // Prefix the ID with 'individual'
            name: user.Name,
            type: 'individual'
        })) : []),
        ...(Array.isArray(CandidateGroupData) ? CandidateGroupData.map(group => ({
            id: `group-${group.CandidateGroupId}`, // Prefix the ID with 'group'
            name: group.CandidateGroupName,
            type: 'group'
        })) : [])
    ];

    return (
        <div className='modal-overlayy'>
            <div className='modal-contentt'>
                <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3 px-4'>
                    <Heading heading={"Edit Candidate Data"} />
                    <div className='self-center'>
                        <CloseIconButton action={handleClose} />
                    </div>
                </div>

                <form className='flex flex-col gap-10 px-5 mb-5' onSubmit={formik.handleSubmit}>
                    <div>
                        <InputWithDropDownv2
                            data={combinedCandidateList}
                            dataField="id"
                            dataText="name"
                            inputId="selectCandidate"
                            inputLabel="Select Candidate"
                            inputName='selectedCandidate'
                            multiple={true}
                            onChange={(event, newValue) => {
                                const selectedIndividualIds = newValue
                                    .filter(item => item.type === 'individual')
                                    .map(item => item.id.replace('individual-', ''));

                                const selectedGroupIds = newValue
                                    .filter(item => item.type === 'group')
                                    .map(item => item.id.replace('group-', ''));
                                formik.setFieldValue('usersIdArray', selectedIndividualIds);
                                formik.setFieldValue('groupIdArray', selectedGroupIds);
                                formik.setFieldValue('selectedCandidatedata', newValue.map(item => item.id)); // Store full ID with prefixes
                            }}
                            inputValue={combinedCandidateList.filter(item =>
                                (formik.values.selectedCandidatedata || []).includes(item.id)
                            ) || []}
                        />
                    </div>

                    {message && <div>{message}</div>}
                    <Button variant='contained' className='mt-4' id='editCandidateData' type='submit'>
                        Save Changes
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default EditModal;
