import { Button, CircularProgress, Divider } from '@mui/material';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import './exam.css'
import DownloadReport from '../../assessment/newfeatures/DownlodReport';
import Heading from '../../reusableComp/Heading';

function ScoreSummerySheet() {
    const [assessmentDetails, setassessmentDetails] = useState();
    const [questionMarks, setquestionMarks] = useState();
    const [Basis, setBasis] = useState('');
    const [flag, setflag] = useState(false);
    const [JobroleName, setJobroleName] = useState('')
    const location = useLocation();
    const AssessmentAttemptId = location?.state?.data;

    const getScoreData = async () => {
        try {
            // Introduce a delay of 5 seconds (5000 milliseconds)
            await new Promise(resolve => setTimeout(resolve, 5000));

            const response = await fetch(`${process.env.REACT_APP_API_URL}/ScoreCard/detailedresult`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: AssessmentAttemptId })
            });

            const result = await response.json();

            if (response.ok) {
                setassessmentDetails(result.assessmentInfo);
                setquestionMarks(result.data);
                setBasis(result.assessmentBasis);
                setJobroleName(result.jobroleName)
                setflag(true);
            }
        } catch (error) {
            console.error('Fetch Error:', error);
        }
    };


    useEffect(() => {
        getScoreData();
    }, []);

    const downloadPDF = () => {
        const content = document.querySelector('.scoreCard');
        html2canvas(content).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save('marksheet.pdf');
        });
    };
    const componentRef = useRef();
    const marksobtained = questionMarks?.reduce((acc, current) => acc + current.Score, 0)
    console.log(marksobtained)
    return (
        <div className="p-4 scoreCard" >


            {!flag ? (
                <div className="flex justify-center items-center h-screen">
                    <CircularProgress />
                </div>
            ) : (
                <div ref={componentRef}>
                    <div className="fixed right-8 bottom-20 touch-pinch-zoom">
                    </div>

                    <div>
                        <Heading heading='Score Card' />
                    </div>

                    {/* Assessment Details Section */}
                    <section className="bg-white p-6 rounded-lg shadow-lg mb-6 border border-gray-200">
                        <h2 className="text-2xl font-semibold mb-4">Assessment Details</h2>
                        <div className="">
                            {assessmentDetails?.map((detail) => (
                                <div
                                    key={detail.AssessmentAttemptId}
                                    className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 assessmentDetails"
                                >
                                    <div className="flex flex-col mb-4">
                                        <h3 className="text-lg font-semibold text-gray-800 mb-1">Assessment Name</h3>
                                        <p className="text-xl font-bold text-gray-900">{detail.NameOfAssessment}</p>
                                    </div>
                                    <div className="flex flex-col mb-4">
                                        <h3 className="text-lg font-semibold text-gray-800 mb-1">Assessment Date</h3>
                                        <p className="text-xl font-bold text-gray-900">{new Date(detail.AssessmentDateTime).toLocaleDateString()}</p>
                                    </div>
                                    <div className="flex flex-col mb-4">
                                        <h3 className="text-lg font-semibold text-gray-800 mb-1">Candidate Name</h3>
                                        <p className="text-xl font-bold text-gray-900">{detail.candidateName}</p>
                                    </div>
                                    <div className="flex flex-col mb-4">
                                        <h3 className="text-lg font-semibold text-gray-800 mb-1">Candidate Email</h3>
                                        <p className="text-md font-bold text-gray-900 overflow-hidden">{detail.candidateEmail}</p>
                                    </div>
                                    <div className="flex flex-col mb-4">
                                        <h3 className="text-lg font-semibold text-gray-800 mb-1">Total Marks</h3>
                                        <p className="text-xl font-bold text-gray-900 w-10 text-center ">
                                            {detail.finalScore || marksobtained}
                                            <Divider className="w-10 font-bold " orientation="horizontal" flexItem />
                                        </p>
                                        <p className="text-xl font-bold text-gray-900 w-10 text-center ">
                                            {detail.TotalMarks}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>

                    {/* Question Marks Section */}
                    <section className="bg-white p-6 rounded-lg shadow-lg border border-gray-200">
                        {/* <h2 className="text-2xl font-semibold mb-4">Marks for each Question</h2> */}
                        <div><span className='text-2xl tex-gray-800 mr-3'>{JobroleName} - </span><span className='text-2xl tex-gray-800 mr-3'>{assessmentDetails[0].finalScore}/{assessmentDetails[0].TotalMarks}</span></div>
                        {(Basis == 'competency' || Basis == 'jobrole') && <div className="container mx-auto p-4">
                            {/* Iterate over each competency */}
                            {questionMarks.map((competency, index) => (
                                <div key={index} className="bg-white shadow-md rounded-lg p-6 mb-6">
                                    <h2 className="text-xl font-bold text-blue-700 mb-4">Competency: {competency.Name}</h2>

                                    {/* Display competency marks */}
                                    <div className="flex justify-between mb-4">
                                        <p className="text-gray-700">Obtained Marks: {competency.obtainedMarks}/{competency.TotalMarks}</p>
                                    </div>

                                    {/* Iterate over each skill under the competency */}
                                    {competency.skills.map((skill, skillIndex) => (
                                        <div key={skillIndex} className="bg-gray-50 rounded-lg p-4 mb-4">
                                            <h3 className="text-lg font-semibold text-orange-800-600 mb-2">Skill: {skill.skillName}</h3>

                                            {/* Display skill marks */}
                                            <div className="flex justify-between mb-2">
                                                <p className="text-gray-600">Obtained Marks: {skill.obtainedMarks}/{skill.TotalMarks}</p>
                                            </div>

                                            {/* Iterate over each question under the skill */}
                                            <div>
                                                {skill.question.map((question, qIndex) => (
                                                    <div key={qIndex} className="bg-white border-l-4 border-blue-500 rounded-lg p-3 mb-3">
                                                        <p className="text-sm font-medium text-gray-800">Question: {question.question}</p>
                                                        <p className="text-sm text-gray-600">Marks Obtained: {question.obtainedMarks !== null ? question.obtainedMarks : 'N/A'}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>}

                        {Basis == 'skills' && <div className="container mx-auto p-4">
                            {/* Iterate over each competency */}
                            <div className="bg-white shadow-md rounded-lg p-6 mb-6">


                                {questionMarks.map((skill, skillIndex) => (
                                    <div key={skillIndex} className="bg-gray-50 rounded-lg p-4 mb-4">
                                        <h3 className="text-lg font-semibold text-orange-800-600 mb-2">Skill: {skill.skillName}</h3>

                                        {/* Display skill marks */}
                                        <div className="flex justify-between mb-2">
                                            <p className="text-gray-600">Obtained Marks: {skill.obtainedMarks}/{skill.TotalMarks}</p>
                                        </div>

                                        {/* Iterate over each question under the skill */}
                                        <div>
                                            {skill.question.map((question, qIndex) => (
                                                <div key={qIndex} className="bg-white border-l-4 border-blue-500 rounded-lg p-3 mb-3">
                                                    <p className="text-sm font-medium text-gray-800">Question: {question.question}</p>
                                                    <p className="text-sm text-gray-600">Marks Obtained: {question.obtainedMarks !== null ? question.obtainedMarks : 'N/A'}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>}


                    </section>
                </div >
            )
            }

        </div >
    );
}

export default ScoreSummerySheet;
