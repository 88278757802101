


import React, { useEffect, useState } from 'react'
import ManageModule from './ManageReuseble/ManageModule'


function AssesmentResults() {
    const [Data, setData] = useState('')
    const [recentData, setRecentformData] = useState('');
    const [insertionStatus, setInsertionStatus] = useState(false);
    const [datakeys, setdatakeys] = useState([]) // table heading names without id , if you want to show all columns , pass it in tableheadings
    console.log(insertionStatus)


    useEffect(() => {
        if (insertionStatus) {
            setInsertionStatus(false);
            getData()
        }
    }, [insertionStatus, recentData]);
    const getData = async () => {
        const UserId = localStorage.getItem('userId')
        const response = await fetch(`${process.env.REACT_APP_API_URL}/assessmentResults`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ UserId })
        })
        const result = await response.json();
        if (response.ok) {
            if (result.data.length > 0) {
                setData(result.data)
                setdatakeys(Object.keys(result.data[0]))
            }

        }

    }
    console.log(Data)
    useEffect(() => {
        getData()
    }, [])

    function transformDateTimes(dataArray) {
        function formatUTCDate(utcDateStr) {
            const date = new Date(utcDateStr);

            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: true
            };

            return date.toLocaleString('en-US', options);
        }

        // Check if dataArray is an array before mapping
        if (Array.isArray(dataArray)) {
            return dataArray.map(item => ({
                ...item,
                AssessmentDateTime: formatUTCDate(item.AssessmentDateTime)
            }));
        } else {
            // Return an empty array or handle the case where dataArray is not an array
            console.warn('Expected an array but received:', dataArray);
            return [];
        }
    }


    const transformedData = transformDateTimes(Data);
    console.log(transformedData)

    const columns = [
        { header: 'Name', field: 'Name' },
        { header: 'Assessment Name', field: 'NameOfAssessment' },
        { header: 'Assessment Date', field: 'AssessmentDateTime' },
        { header: 'Total Marks', field: 'TotalMarks' },
        { header: 'Final Score', field: 'finalScore' },

    ];




    return (
        <div>


            <ManageModule
                pageHeading='Menu List'
                data={transformedData}
                permissionprefix='AssessmentResults'
                tableHeadings={['Name', 'Assessment Name', "Assessment Date", "Final Score"]}  // can give table heading  name in array too eg ['h1','h5']
                tableName={'tablename'}
                formfieldNames={datakeys} // can gives fields name in array too. eg. ['column1','column5']
                setRecentformData={setRecentformData}
                setInsertionStatus={setInsertionStatus}
                columns={columns}

            />




        </div>
    )
}

export default AssesmentResults
