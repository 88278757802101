import React, { useEffect, useState } from 'react';
import OptionsList from './OptionsList';
import AnswerList from './AnswerList';
import { CustomButton } from '../../../ButtonsIconsInputs/IconButtons';
import { Chip } from '@mui/material';
import StanderdConstants from '../../../services/StanderdConstants';

const QuestionCard = ({ question, index, assessmentId, setChangeOccured, AssessmentSetNumber, assessmentType }) => {
    const [Message, setMessage] = useState()
    const [Question, setQuestion] = useState([])
    const SameQForallId = Question?.SameQForallId;
    console.log(AssessmentSetNumber)
    useEffect(() => {

        setQuestion(question)
    }, [question])
    console.log(assessmentId)

    const ChangeQuesiton = async (Qid) => {
        console.log(Qid)
        const response = await fetch(`${process.env.REACT_APP_API_URL}/assessment_preview/ChangeQuestion`, {
            method: 'Post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Qid, assessmentId, AssessmentSetNumber, SameQForallId })
        })
        const result = await response.json();
        if (response.ok) {
            setChangeOccured(true)
            setQuestion(result.newQuestion[0])
            setMessage('')
        }
        else {
            setMessage(result.message)
        }




    }



    const getChipColor = (difficulty) => {
        switch (difficulty) {
            case 'hard':
                return 'error'; // Replace with your red color reference
            case 'normal':
                return 'success'; // Default primary color
            // Add more cases if needed
            default:
                return 'default'; // Fallback color
        }
    };
    console.log(assessmentType)



    const isMultipleCorrect = Question?.questionType?.includes(StanderdConstants.MCQMULTIPLE);
    console.log(isMultipleCorrect)
    console.log(Question)
    return (
        <div className="card p-4 border rounded shadow-sm">
            <div className='flex justify-between items-center'> <h3 className="font-bold">Question {index + 1}</h3>


                {assessmentType !== 'dynamic' && <div className='w-fit flex-shrink-0 ' title='change question'>
                    <CustomButton onClick={() => ChangeQuesiton(Question.questionId)} Text='Change Question' customcss='w-fit' />
                </div>}

                {Question?.questionType}
            </div>


            {question.questionType === 'free' ? (
                <p className=' pr-4 break-words '>{Question.question}</p>

            ) : (
                <p className=' pr-4 break-words '>{Question.question}</p>

            )}
            {Question?.questionType?.includes('mcq') && (
                <OptionsList options={Question.optionDetails} isMultipleCorrect={isMultipleCorrect} />
            )}
            {Question.questionType === 'free' && (
                <AnswerList answers={Question.FreeAnswerDetails} />
            )}
            <p className="mt-2 text-right">
                <Chip
                    label={Question.difficulty}
                    color={getChipColor(Question.difficulty)}
                />            </p>

            <span> {Message && Message}</span>
        </div>
    );
};

export default QuestionCard;
