import React, { useEffect, useState } from 'react';
import { CustomSelect } from '../../ButtonsIconsInputs/CustomInputs';
import StanderdConstants from '../../services/StanderdConstants';

function SkillsDropdown({ skill, setSkill }) {
  const [skills, setSkills] = useState([]);

  const api_url = process.env.REACT_APP_API_URL;

  const getSkillDetails = async () => {
    try {
      let result = await fetch(`${api_url}/skills/`, {
        method: 'post',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ UserId: StanderdConstants.UserId })
      });
      result = await result.json();
      setSkills(result.data);

    } catch (error) {
      console.error('Error fetching skills:', error);
    }
  };

  useEffect(() => {
    getSkillDetails();
  }, []);

  return (


    <CustomSelect
      options={skills}
      value={skill}
      onChange={(e) => setSkill(e.target.value)}
      dataField='skillId'
      dataText='skillName'
      label='Associate Question To Skill'


    />
  );
}

export default SkillsDropdown;
