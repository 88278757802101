import React, { useEffect, useState } from 'react'
import { Field, Form, useFormik } from 'formik';
import Heading from '../../reusableComp/Heading';
import { CustomSelectv2, InputBox } from '../../../ButtonsIconsInputs/CustomInputs';
import { Button } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CloseIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import { DragHandleOutlined, DragIndicator } from '@mui/icons-material';
import StanderdConstants from '../../../services/StanderdConstants';
import SubscriptionPlanInfo from '../../reusableComp/SubscriptionPlanInfo';

function CreateAssessmentSeries({ handleclose, setStatus }) {
    const [AssessmentList, SetAssessmentList] = useState([]);
    const [arrangedAssessmentList, setArrangedAssessmentList] = useState([]);
    const [message, setMessage] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState('free');
    const [selectedCurrency, setSelectedCurrency] = useState('usd');
    const [subscriptionAmount, setSubscriptionAmount] = useState(0);

    const formik = useFormik({
        initialValues: {
            AssessmentList: [],
            SeriesName: '',
            DateValidFrom: '',
            DateValidTo: '',
            SeriesImage: '',


        },

        onSubmit: values => {
            console.log(values);
            const formData = new FormData();
            formData.append('CreaterId', StanderdConstants.UserId)
            formData.append('AssessmentList', values.AssessmentList)
            formData.append('SeriesName', values.SeriesName)
            formData.append('DateValidFrom', values.DateValidFrom)
            formData.append('DateValidTo', values.DateValidTo)
            formData.append('SeriesImage', values.SeriesImage)
            formData.append('selectedPlan', selectedPlan)
            formData.append('selectedCurrency', selectedCurrency)
            formData.append('subscriptionAmount', subscriptionAmount)
            saveData(formData);
        }
    });
    console.log(AssessmentList)

    const saveData = async (data) => {
        try {
            // Add arranged assessments to the data
            data.arrangedAssessmentList = arrangedAssessmentList;

            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessmentseries/Add`, {
                method: 'post',
                body: data
            });

            const response = await result.json();
            if (result.ok) {
                setMessage(response.message)
                setStatus(true)
            }

        } catch (error) {
            setMessage(error.message);
        }
    }

    const getAssessments = async () => {
        try {
            const userId = localStorage.getItem('userId');
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessment_get`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId })
            });
            const response = await result.json();

            if (result.ok) {
                SetAssessmentList(response.assessments);
            }
        } catch (error) {
            setMessage(error.message);
        }
    };

    useEffect(() => {
        getAssessments();
    }, []);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(formik.values.AssessmentList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        formik.setFieldValue('AssessmentList', items);
        setArrangedAssessmentList(items);
    }

    const handleFileChange = (event) => {
        formik.setFieldValue('SeriesImage', event.currentTarget.files[0]);
    };


    return (
        <div className='modal-overlayy'>
            <div className='modal-contentt'>
                <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3     '>
                    <div className='invisible'>content</div>
                    <Heading heading={"Create Assessment Series"} />
                    <div className='self-center'>
                        <CloseIconButton action={handleclose} />
                    </div>
                </div>

                <form className='flex flex-col gap-10 px-5 mb-5' onSubmit={formik.handleSubmit}>
                    <div className='w-full'>
                        <InputBox
                            inputId='seriesName'
                            inputLabel='SeriesName'
                            inputName='SeriesName'
                            inputValue={formik.values.SeriesName}
                            onChange={formik.handleChange}
                            customcss='w-full'
                        />
                    </div>
                    <div>
                        <div>
                            <label htmlFor="DateValidFrom">Date Valid From:</label>
                            <input
                                type="date"
                                id="DateValidFrom"
                                name="DateValidFrom"
                                value={formik.values.DateValidFrom}
                                onChange={formik.handleChange}
                            />
                        </div>

                        <div style={{ marginTop: 16 }}>
                            <label htmlFor="DateValidTo">Date Valid To:</label>
                            <input
                                type="date"
                                id="DateValidTo"
                                name="DateValidTo"
                                value={formik.values.DateValidTo}
                                onChange={formik.handleChange}
                            />
                        </div>

                    </div>
                    <div className="form-group">
                        <label htmlFor="add-CompanyLogo" className=''>Series Images</label>
                        <input
                            id="add-sereisimage"
                            name="SereisImage"
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                        />

                    </div>
                    <div>
                        <SubscriptionPlanInfo
                            selectedPlan={selectedPlan}
                            setSelectedPlan={setSelectedPlan}
                            selectedCurrency={selectedCurrency}
                            setSelectedCurrency={setSelectedCurrency}
                            subscriptionAmount={subscriptionAmount}
                            setSubscriptionAmount={setSubscriptionAmount}
                        />
                    </div>


                    <div>
                        <CustomSelectv2
                            options={AssessmentList}
                            dataField="assessmentId"
                            dataText="nameOfAssessment"
                            inputId="selectassessment"
                            label="Select Assessment"
                            name="AssessmentList"
                            multiple
                            onChange={(selectedOptions) => {
                                formik.setFieldValue("AssessmentList", selectedOptions);
                                setArrangedAssessmentList(selectedOptions);
                            }}
                            value={formik.values.AssessmentList || []}
                        />
                    </div>


                    {/* DragDropContext for reordering */}
                    <div className="assessment-list-container"> {/* Custom class for scrollable area */}
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <h4>Arrange Assessments</h4>

                            <Droppable droppableId="assessments">
                                {(provided) => (
                                    <ul className="assessments-list" {...provided.droppableProps} ref={provided.innerRef}>

                                        {formik.values.AssessmentList.map((item, index) => (
                                            <Draggable key={item} draggableId={item.toString()} index={index}>
                                                {(provided) => (
                                                    <div className="flex flex-row items-center mb-3">
                                                        <span className="mr-4">{index + 1}.</span>
                                                        <li
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className="bg-white rounded-lg shadow-md p-4 flex justify-between items-center border border-gray-200 w-full"
                                                        >
                                                            <span className="font-semibold text-gray-700">
                                                                {AssessmentList?.find(assessment => assessment?.assessmentId === item)?.nameOfAssessment || 'Unknown Assessment'}
                                                            </span>
                                                            <span className="text-blue-500 font-medium cursor-pointer">
                                                                <DragIndicator />
                                                            </span>
                                                        </li>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </ul>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>

                    {message}

                    <Button variant='contained' className='mt-4' id='addCompany' type='submit'>
                        Save
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default CreateAssessmentSeries;
