import React from 'react';
import { InputBox, CustomSelectv2, CustomSelectv5 } from '../../ButtonsIconsInputs/CustomInputs';

function SubscriptionPlanInfo({ selectedPlan, setSelectedPlan, selectedCurrency, setSelectedCurrency, subscriptionAmount, setSubscriptionAmount }) {
    // Options for the dropdowns
    const subscriptionPlans = [
        { id: 'free', name: 'Free' },
        { id: 'premium', name: 'Premium' },
        { id: 'enterprise', name: 'Enterprise' },
    ];

    const currencyTypes = [
        { id: 'usd', name: 'USD' },
        { id: 'eur', name: 'EUR' },
        { id: 'inr', name: 'INR' },
    ];

    return (
        <div className="space-y-4">
            {/* Subscription Plan Dropdown */}
            <CustomSelectv5
                options={subscriptionPlans}
                dataField="id"
                dataText="name"
                label="Subscription Plan"
                inputName="plan"
                onChange={(v) => setSelectedPlan(v)} // Handle selected plan change
                value={selectedPlan} // Pass the selected plan value
            />

            {/* Currency Type Dropdown */}
            <CustomSelectv5
                options={currencyTypes}
                dataField="id"
                dataText="name"
                label="Currency"
                inputName="currency"
                onChange={(v) => setSelectedCurrency(v)} // Handle selected currency change
                value={selectedCurrency} // Pass the selected currency value
            />

            {/* Subscription Amount Input Box */}
            <InputBox
                inputLabel="Subscription Plan Amount"
                inputName="subscriptionAmount"
                inputValue={subscriptionAmount}
                onChange={(e) => setSubscriptionAmount(e.target.value)} // Handle amount change
            />
        </div>
    );
}

export default SubscriptionPlanInfo;
