import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { SubscribeButton } from '../../ButtonsIconsInputs/IconButtons';
import Subscription from '../reusableComp/Subscription';
import StanderdConstants from '../../services/StanderdConstants';

function GenericChannelCard({
    title,
    subtitle,
    imageSrc,
    badgeText,
    badgeColor,
    ShowChannelContent,
    item,
    actions = []  // Pass an array of actions
}) {

    console.log(StanderdConstants.UserId)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [imgLoaded, setImgLoaded] = useState(true);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    console.log(item)

    return (
        <div className={`relative justify-self-center w-full rounded-lg overflow-hidden shadow-lg border transition-transform transform hover:scale-105`}>
            {/* Badge */}
            {badgeText && (
                <div className={`absolute top-2 right-2 text-white text-xs font-semibold px-2 py-1 rounded-3xl ${badgeColor}`}>
                    {badgeText}
                </div>
            )}

            {/* Image Container */}
            <div className="w-full h-48 bg-gray-200 flex items-center justify-center">
                {imgLoaded ? (
                    <img
                        className="w-full h-full object-cover"
                        alt="thumbnail"
                        src={imageSrc}
                        onLoad={() => setImgLoaded(true)} // Set loaded state to true when image loads
                        onError={() => setImgLoaded(false)} // Set loaded state to false if there's an error
                    />
                ) : (
                    <span className="text-gray-500">No Image</span>
                )}
            </div>

            {/* Content */}
            <div>
                <h2 className="font-bold text-lg mb-2 bg-sky-600 text-gray-50 truncate hover:cursor-pointer" onClick={ShowChannelContent}>
                    <span className="px-2">{title}</span>
                </h2>
                <p className="p-4">{subtitle}</p>
                <span className=' flex justify-left m-2'>
                    {!item.IsSubscribed && <Subscription
                        Type='channel'
                        Id={item?.ChannelId}
                        SubscriptionAmount={item?.SubscriptionPlanAmount || 0}
                        SubscriptionPlanTypeId={item?.SubscriptionPlanTypeId}


                    />}
                </span>

            </div>

            {/* Actionable Three-Dot Menu */}
            <div className="absolute bottom-2 rounded-md right-2">
                <IconButton className='mt-2' onClick={handleClick}>
                    <MoreVert />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}


                >
                    {actions?.length > 0 && (

                        <div className="flex  flex-col justify-center px-1">
                            {actions?.map((action, actionIndex) => {
                                const ButtonComponent = action.ButtonComponent;
                                return (
                                    <ButtonComponent
                                        key={actionIndex}
                                        action={() => { handleClose(); action.handler(item, actionIndex) }}
                                        tooltip={action.tooltip}
                                        id={actionIndex}
                                        itemName={''}
                                        itemData={item}
                                        label={action.label}
                                        className={action.className}
                                    />
                                );
                            })}
                        </div>

                    )}
                </Menu>
            </div>
        </div>
    );
}

export default GenericChannelCard;
