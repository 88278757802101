import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { CloseIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import Heading from '../../reusableComp/Heading';
import { InputBox, InputWithDropDownv2 } from '../../../ButtonsIconsInputs/CustomInputs';
import { Button } from '@mui/material';
import StanderdConstants from '../../../services/StanderdConstants';
import MUIRichTextEditor from 'mui-rte';
import { ContentState, convertFromHTML, convertFromRaw, convertToRaw } from 'draft-js';
import Papa from 'papaparse';
import { stateToHTML } from 'draft-js-export-html';

function AddModal({ handleClose, setStatus }) {
    const [emailTemplate, setEmailTemplate] = useState(null);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [emailBody, setEmailBody] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [EmailSentStatus, SetEmailSentStatus] = useState(false);
    const [CompanyId, setCompanyId] = useState('')
    const [CompanyName, setCompanyName] = useState('')


    // get Company Name

    const formik = useFormik({
        initialValues: {
            recipients: [],
            Title: '',
            subject: '',
            emailBody: '',
            useUniqueUrl: false
        },

        onSubmit: values => {
            console.log('Submitted Values:', values);
            const contentState = convertFromRaw(JSON.parse(emailBody || '{}'));
            const htmlContent = stateToHTML(contentState);
            saveData({ ...values, CompanyId, CompanyName, EmailBody: htmlContent });
        }
    });
    async function checkIfUserBelongsToOrganization() {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/companies/IsUserBelongsToOrganisation/?Id=${StanderdConstants.UserId}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!res.ok) {
                // Handle non-200 status codes
                return false;
            }

            const result = await res.json();
            console.log(result)

            if (result && result.company) {
                console.log(result)


                setCompanyId(result.CompanyId)
                setCompanyName(result.company)
            } else {
                return false;
            }

        } catch (error) {
            // Handle network or unexpected errors
            console.error('Error fetching organization data:', error);
            return false;
        }
    }

    useEffect(() => {
        formik.setFieldValue('CompanyId', CompanyId)

    }, [CompanyId])

    useEffect(() => {
        formik.setFieldValue('CompanyName', CompanyName)

    }, [CompanyName])

    const getEmailTemplate = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/Invitation/EmailTemplate/?Id=${StanderdConstants.INVITE_COMPANY_EMPLOYEES}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            const result = await response.json();
            console.log(result)
            setEmailTemplate(result.data);
        } catch (error) {
            setError(error.message);
        }
    };



    useEffect(() => {
        getEmailTemplate()
    }, []);

    useEffect(() => {
        checkIfUserBelongsToOrganization()

    }, [])


    console.log(formik.values)


    const saveData = async (values) => {
        SetEmailSentStatus(true)
        const result = await fetch(`${process.env.REACT_APP_API_URL}/users/SendInvite`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values)
        });
        const response = await result.json();
        console.log(response)
        if (result.ok) {
            console.log("data saved")
            SetEmailSentStatus(false)
        }
        else {
            SetEmailSentStatus(true)
        }
        console.log("Saving Menu Data: ", values);


        setStatus(true);
    };

    useEffect(() => {
        if (selectedFile) {
            handleCSVUpload();
        }
    }, [selectedFile]);

    const handleCSVUpload = () => {
        Papa.parse(selectedFile, {
            header: true,
            complete: (result) => {
                const emails = result.data.map(row => row.Email).filter(Boolean);
                formik.setFieldValue('recipients', emails);
            },
            error: (error) => {
                console.error('Error parsing CSV:', error);
            }
        });
    };


    useEffect(() => {
        if (emailTemplate) {
            const blocksFromHTML = convertFromHTML(emailTemplate.Body);
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            const rawContentState = convertToRaw(contentState);

            formik.setValues({
                Title: emailTemplate.Title || '',
                subject: emailTemplate.Subject || '',
                recipients: formik.values.recipients,
                useUniqueUrl: formik.values.useUniqueUrl,
            });

            setEmailBody(JSON.stringify(rawContentState));
        }
    }, [emailTemplate]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };


    const handleChangeRichTextBox = (data) => {
        setEmailBody(data);
    };
    console.log(formik.errors)

    return (
        <div className='modal-overlayy'>
            <div className='modal-contentt'>
                <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3'>
                    <div className='invisible'>content</div>
                    <Heading heading={"Add People To Your Organisation"} />
                    <div className='self-center'>
                        <CloseIconButton action={handleClose} />
                    </div>
                </div>

                <form className='flex flex-col gap-10 px-5 mb-5' onSubmit={formik.handleSubmit}>
                    {/* Input for Parent Menu Name */}
                    <div className='w-full'>
                        {CompanyId}
                        {CompanyName}

                    </div>


                    {/* Dropdown for selecting menus */}
                    <div>
                        <label className='text-sm'>Type comma-separated email addresses below or upload a CSV file</label>
                        <InputBox
                            inputLabel='Recipients'
                            inputName='recipients'
                            multiline={true}
                            onChange={(e) => {
                                const emailArray = e.target.value.split(',').map(email => email.trim());
                                formik.setFieldValue('recipients', emailArray);
                            }}
                            inputValue={formik.values.recipients}
                        />

                        <div className="flex justify-center my-4">
                            <span className="text-center w-full">or</span>
                        </div>

                        <div className='flex justify-center my-4'>
                            <span className='text-center'>
                                <label className='hover:cursor-pointer hover:text-blue-700 hover:underline'>
                                    <input
                                        type='file'
                                        accept='.csv'
                                        hidden
                                        onChange={handleFileChange}
                                    />
                                    <h1>Upload A CSV File</h1>
                                </label>

                                <a href={`${process.env.REACT_APP_API_URL}/StaticFiles/sample_names_email.csv`} download >
                                    <h1 className='hover:text-blue-700 hover:underline'>Download Sample Template For CSV</h1>
                                </a>
                            </span>
                        </div>
                    </div>

                    <div>
                        <label className='text-sm'>Email Body</label>
                        <MUIRichTextEditor
                            defaultValue={emailBody || ''} // Avoid flickering
                            onSave={handleChangeRichTextBox}
                            inlineToolbar={true}
                        />
                    </div>

                    {formik.errors.selectedMenus && <p className="text-red-500">{formik.errors.selectedMenus}</p>}

                    {/* Save Button */}
                    {<Button variant='contained' disabled={EmailSentStatus} type='submit'>{EmailSentStatus ? 'Sending Invite...' : 'Send invite'}</Button>
                    }
                </form>
            </div >
        </div >
    );
}

export default AddModal;
