import React from 'react';

const skillsTable = [
    { avgScore: 7.1, skillName: "Universal competencies for consulting services", review: "Adequate" },
    { avgScore: 7.5, skillName: "Adaptability", review: "Adequate" },
    { avgScore: 4.0, skillName: "Client focus", review: "Needs Help" },
    { avgScore: 7.5, skillName: "Decision making", review: "Adequate" },
    { avgScore: 8.7, skillName: "Planning and organizing", review: "Rockstar" },
    { avgScore: 7.9, skillName: "Results orientation", review: "Adequate" }
];

const SkillsTable = ({ tableData }) => {
    console.log(tableData)
    return (

        <div className="overflow-x-auto bg-gray-100  rounded-lg shadow-lg w-fit">
            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-200">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Skill Name</th>
                        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Average Score</th>
                        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Review</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-300">
                    {tableData.map((skill, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 text-wrap max-w-40">{skill.Name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">{skill.obtainedMarks}/{skill.TotalMarks}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                {(() => {
                                    const percentage = (skill.obtainedMarks / skill.TotalMarks) * 100;

                                    let review;
                                    if (percentage > 70) {
                                        review = 'Rockstar';
                                    } else if (percentage > 50) {
                                        review = 'Adequate';
                                    } else {
                                        review = 'Needs Improvement';
                                    }

                                    const reviewClass = review === 'Rockstar'
                                        ? 'bg-green-100 text-green-800'
                                        : review === 'Adequate'
                                            ? 'bg-yellow-100 text-yellow-800'
                                            : 'bg-red-100 text-red-800';

                                    return (
                                        <span
                                            className={`inline-flex px-3 py-1 text-xs font-medium rounded-full ${reviewClass}`}
                                        >
                                            {review}
                                        </span>
                                    );
                                })()}
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SkillsTable;
