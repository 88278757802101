import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Autocomplete, Checkbox, List, ListItem, ListItemText, MenuItem, Popper, TextField } from '@mui/material'
import { Field } from 'formik';
import React, { useState } from 'react'



export function InputBox({ customcss, autocomplete = 'off', multiline = false, onBlur, helperText, error = '', required = false, type = 'text', inputValue, inputName, onChange, inputLabel, inputCss, inputId }) {
    return (
        <TextField
            autoComplete={autocomplete}
            required={required}
            name={inputName}
            value={inputValue}
            id={inputId}
            type={type}
            onChange={(e, value) => onChange(e, value)}
            label={inputLabel}
            variant="standard"
            helperText={helperText}
            error={error}
            onBlur={onBlur}
            multiline={multiline}
            className='w-full '

        />
    )
}




export function InputWithDropDown({ data, multiple = false, dataText, dataField, inputValue, inputName, onChange, inputLabel, inputId }) {
    return (
        <Autocomplete
            id={inputId}

            options={data}
            value={inputValue}
            getOptionLabel={(option) => option[dataText] || ''}  // What to display for each option
            className='w-full'
            isOptionEqualToValue={(option, value) => option[dataField] === value[dataField]}
            onChange={(event, value) => onChange(event, value)}
            renderInput={(params) => (
                <TextField

                    {...params}
                    label={inputLabel}
                    variant="standard"
                />
            )}
        />
    )
}



export function InputWithDropDownv2({
    data,
    multiple = true,
    dataText,
    dataField,
    inputValue,
    inputName,
    onChange,
    inputLabel,
    inputId
}) {
    console.log(inputValue)
    const icon = <CheckBoxOutlineBlank fontSize="small" />;
    const checkedIcon = <CheckBox fontSize="small" />;

    return (
        <Autocomplete
            id={inputId}
            multiple={multiple}
            options={data}

            value={inputValue}
            disableCloseOnSelect
            getOptionLabel={(option) => option[dataText] || ''} // Display the text for each option
            isOptionEqualToValue={(option, value) => option[dataField] == value[dataField]} // Compare by unique field
            onChange={(event, value) => onChange(event, value)} // Handle selection changes
            renderOption={(props, option, { selected }, index) => (
                <li {...props} key={index} >
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option[dataText]}
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={inputLabel}
                    variant="standard"
                />
            )}
        />
    );
}








export function CustomSelect({ name, label, dataText, dataField, options = [], value, onChange, }) {
    return (
        <TextField
            id={name}
            name={name}
            select
            value={value}
            label={label}
            onChange={(e, value) => onChange(e)}
            variant='standard'
            className='w-full'
            slotProps={{
                select: {

                },
            }}
        >
            {options.map((option) => (
                <MenuItem key={option[dataField]} value={option[dataField]}>
                    {option[dataText]}
                </MenuItem>
            ))}
        </TextField>
    );
};





export function CustomSelectv2({
    name,
    label,
    dataText,
    dataField,
    options = [],
    value = [],
    onChange,
    multiple = false,
    inputId,
    Blur,
    error,
    helperText
}) {
    const handleChange = (event) => {
        const { value } = event.target;
        onChange(multiple ? value : [value]); // Handle multiple or single selection based on the `multiple` prop
    };

    return (
        <TextField
            id={inputId}
            name={name}
            select
            value={value}
            label={label}
            onChange={handleChange}
            variant="standard"
            onBlur={Blur}
            error={error}
            helperText={helperText}
            className="w-full"
            SelectProps={{
                multiple, // Enable multi-select functionality
                renderValue: (selected) =>
                    options
                        .filter(option => selected?.includes(option[dataField]))
                        .map(option => option[dataText])
                        .join(', '),
            }}
        >
            {options.map((option) => (
                <MenuItem key={option[dataField]} value={option[dataField]}>
                    {multiple && value && (
                        <Checkbox
                            checked={value?.includes(option[dataField])}
                        />
                    )}
                    <ListItemText primary={option[dataText]} />
                </MenuItem>
            ))}
        </TextField>
    );
}

export function CustomSelectv4({
    name,
    label,
    dataText,
    dataField,
    options = [],
    value = [], // For single select, this might need to be a single value, not an array
    onChange,
    multiple = false,
    inputId,
    Blur,
    error,
    helperText
}) {
    const handleChange = (event) => {
        const { value } = event.target;
        onChange(multiple ? value : value); // Keep single value when `multiple` is false
    };

    return (
        <TextField
            id={inputId}
            name={name}
            select
            value={multiple ? value : (value || '')} // Single value handling
            label={label}
            onChange={handleChange}
            variant="standard"
            onBlur={Blur}
            error={error}
            helperText={helperText}
            className="w-full"
            SelectProps={{
                multiple, // Enable multi-select functionality
                renderValue: (selected) =>
                    multiple
                        ? options
                            .filter(option => selected?.includes(option[dataField]))
                            .map(option => option[dataText])
                            .join(', ')
                        : options.find(option => option[dataField] === selected)?.[dataText] || '',
            }}
        >
            {options.map((option) => (
                <MenuItem key={option[dataField]} value={option[dataField]}>
                    {multiple && value && (
                        <Checkbox
                            checked={value?.includes(option[dataField])}
                        />
                    )}
                    <ListItemText primary={option[dataText]} />
                </MenuItem>
            ))}
        </TextField>
    );
}


export const InputBox2 = ({ inputLabel, inputId, inputName, inputValue, onChange, onBlur, required, type, error, helperText }) => {
    return (
        <div className="form-group">
            <TextField
                variant='standard'
                fullWidth
                required={required}
                label={inputLabel}
                id={inputId}
                name={inputName}
                value={inputValue}
                onChange={onChange}
                onBlur={onBlur} // Required for Formik to track touched fields
                type={type}
                error={!!error} // If there's an error, show the error state
                helperText={error ? helperText : ''} // Show the helper text when there's an error
            />
        </div>
    );
}; // Import the generic table component

// CustomSelectv3 shows list of selected items
export function CustomSelectv3({
    name,
    label,
    dataText,
    dataField,
    options = [],
    onChange,
    inputId,
    error,
    helperText
}) {
    const [selectedItems, setSelectedItems] = useState([]);

    const handleSelectionChange = (event) => {


        const selectedValues = [...event.target.selectedOptions].map(option => option.value);
        setSelectedItems(selectedValues);
        onChange(selectedValues);
    };

    return (
        <div className="mb-4">
            <label htmlFor={inputId} className="block text-gray-700 mb-2">{label}</label>
            <select
                id={inputId}
                name={name}
                onChange={handleSelectionChange}
                multiple
                className="w-full p-2 border rounded"
            >
                {options.map((option) => (
                    <option key={option[dataField]} value={option[dataField]}>
                        {option[dataText]}
                    </option>
                ))}
            </select>
            {error && <div className="text-red-500">{helperText}</div>}

            {/* Scrollable Table */}
            <div className="overflow-auto max-h-40 border border-gray-300 rounded mt-4">
                <table className="w-full">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="p-2 text-left">{dataField}</th>
                            <th className="p-2 text-left">{dataText}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedItems.length > 0 ? (
                            selectedItems.map((id, index) => {
                                const selectedItem = options.find(item => item[dataField] === id);
                                return (
                                    <tr key={index} className="border-b">
                                        <td className="p-2">{id}</td>
                                        <td className="p-2">{selectedItem ? selectedItem[dataText] : 'Not Found'}</td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={2} className="p-2 text-center">
                                    No data available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}







export function CustomSelectv5({
    name,
    label,
    dataText,
    dataField,
    options = [],
    value = '', // Default value as empty string for single select
    onChange,
    multiple = false,
    inputId,
    error,
    helperText
}) {
    const handleChange = (event) => {
        const { value: selectedValue } = event.target;
        onChange(selectedValue); // Call the onChange with the selected value
    };

    return (
        <TextField
            id={inputId}
            name={name}
            select
            value={value}
            label={label}
            onChange={handleChange}
            variant="standard"
            error={error}
            helperText={helperText}
            className="w-full"
            SelectProps={{
                multiple, // Enable multi-select functionality if needed
                renderValue: (selected) =>
                    multiple
                        ? options
                            .filter(option => selected?.includes(option[dataField]))
                            .map(option => option[dataText])
                            .join(', ')
                        : options.find(option => option[dataField] === selected)?.[dataText] || '',
            }}
        >
            {options.map((option) => (
                <MenuItem key={option[dataField]} value={option[dataField]}>
                    {multiple && (
                        <Checkbox checked={value?.includes(option[dataField])} />
                    )}
                    <ListItemText primary={option[dataText]} />
                </MenuItem>
            ))}
        </TextField>
    );
}







