import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button } from '@mui/material';
import './App.css';
import Heading from '../reusableComp/Heading';
import { CloseIconButton } from '../../ButtonsIconsInputs/IconButtons';
import { CustomSelectv2, InputBox, InputWithDropDownv2 } from '../../ButtonsIconsInputs/CustomInputs';
import StanderdConstants from '../../services/StanderdConstants';
import SubscriptionPlanInfo from '../reusableComp/SubscriptionPlanInfo';

function EditChannelModal({ handleClose, setStatus, channelData, get, ChannelId }) {
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [AssessmentList, SetAssessmentList] = useState([]);
    const [AssessmentSeriesList, setAssessmentSeriesList] = useState([]);
    const [FetchedChannelData, setFetchedChannelData] = useState()
    const [selectedPlan, setSelectedPlan] = useState('free');
    const [selectedCurrency, setSelectedCurrency] = useState('usd');
    const [subscriptionAmount, setSubscriptionAmount] = useState(0);

    const formik = useFormik({
        initialValues: {
            channelName: '',
            description: '',
            videoFile: null,
            logoFile: null,
            subscriptionPlan: '',
            Visibility: '',
            assessmentIdList: [],
            SeriesIdList: [],
            selectedassessmentData: []
        },
        onSubmit: values => {
            const formData = new FormData();
            formData.append('ChannelId', channelData.ChannelId);
            formData.append('ChannelName', values.channelName);
            formData.append('ChannelDescription', values.description);
            formData.append('ChannelLogo', values.logoFile);
            formData.append('ChannelVideo', values.videoFile);
            formData.append('SubscriptionId', values.subscriptionPlan);
            formData.append('Visibility', values.Visibility);
            formData.append('AssessmentIdList', JSON.stringify(values.assessmentIdList));
            formData.append('SeriesIdList', JSON.stringify(values.SeriesIdList));
            formData.append('selectedPlan', selectedPlan)
            formData.append('selectedCurrency', selectedCurrency)
            formData.append('subscriptionAmount', subscriptionAmount)

            updateChannelData(formData);
        }
    });

    const GetChannelExistingData = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/Channel/GetChannelData/?Id=${ChannelId}`, {
                method: 'get',

            });
            const response = await result.json();
            if (result.ok) {
                setFetchedChannelData(response.data);
            }
        } catch (error) {
            setMessage(error.message);
        }
    };

    const updateChannelData = async (data) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/Channel/EditChannel`, {
                method: 'post',
                body: data,
            });
            const response = await result.json();
            if (result.ok) {
                setStatus(true);
                setMessage(response.message);
            }
        } catch (error) {
            setMessage(error.message);
        }
    };



    useEffect(() => {
        if (FetchedChannelData) {


            formik.setValues({
                channelName: FetchedChannelData.commonChannelData.ChannelName || '',
                description: FetchedChannelData.commonChannelData.ChannelDescription || '',
                subscriptionPlan: FetchedChannelData.commonChannelData.SubscriptionId || '',
                Visibility: FetchedChannelData.commonChannelData.Visibility || '',
                assessmentIdList: FetchedChannelData.assessmentIds || '',
                SeriesIdList: FetchedChannelData.assessmentSeriesIds || '',
                selectedassessmentData: [
                    ...FetchedChannelData.assessmentIds.map(id => `assessment-${id}`),
                    ...FetchedChannelData.assessmentSeriesIds.map(id => `series-${id}`)
                ]

            });
        }
    }, [FetchedChannelData]);



    useEffect(() => {
        if (channelData) {

            const sereisIdArray = channelData?.Series?.map(item => item.AssessmentSeriesId) || []
            const assessmentIdArray = channelData?.Assessments?.map(item => item?.assessmentId) || []
            console.log(sereisIdArray)
            formik.setValues({
                channelName: channelData.ChannelName || '',
                description: channelData.ChannelDescription || '',
                subscriptionPlan: channelData.SubscriptionId || '',
                Visibility: channelData.Visibility || '',
                assessmentIdList: assessmentIdArray || '',
                SeriesIdList: sereisIdArray || '',
                selectedassessmentData: [
                    ...assessmentIdArray.map(id => `assessment-${id}`),
                    ...sereisIdArray.map(id => `series-${id}`)
                ]

            });
        } else {
            GetChannelExistingData()
        }
    }, [channelData]);

    const handleFileChange = (event, fieldName) => {
        formik.setFieldValue(fieldName, event.currentTarget.files[0]);
    };
    const getAssessments = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessment_get`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: StanderdConstants.UserId })
            });
            const response = await result.json();
            if (result.ok) {
                SetAssessmentList(response.assessments);
            }
        } catch (error) {
            setMessage(error.message);
        }
    };

    useEffect(() => {
        getAssessments();
    }, []);

    const getAssessmentSeriesData = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessmentseries/getseriesNames`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: StanderdConstants.UserId })
            });
            const response = await result.json();
            if (result.ok) {
                setAssessmentSeriesList(response.data);
            }
        } catch (error) {
            setMessage(error.message);
        }
    };

    useEffect(() => {
        getAssessmentSeriesData();
    }, []);

    const combinedList = [
        ...(Array.isArray(AssessmentList) ? AssessmentList.map(assessment => ({
            id: `assessment-${assessment.assessmentId}`,
            name: assessment.nameOfAssessment,
            type: 'assessment'
        })) : []), // Ensure AssessmentList is an array

        ...(Array.isArray(AssessmentSeriesList) ? AssessmentSeriesList.map(series => ({
            id: `series-${series.AssessmentSeriesId}`,
            name: series.AssessmentSeriesName,
            type: 'series'
        })) : []) // Ensure AssessmentSeriesList is an array
    ]
    const accessOptions = [
        { value: "private_me", label: "Private - Only to Me" },
        { value: "private_organization", label: "Private - Only to My Organization" },  // Show only if part of an organization
        { value: "public", label: "Public - Everyone" }
    ];

    return (
        <div className='modal-overlayy flex justify-center items-center fixed inset-0 z-50 bg-black bg-opacity-50'>
            <div className='modal-contentt bg-white rounded-lg shadow-lg w-full max-w-4xl h-auto max-h-[90vh]'>

                {/* Modal Header - Non-Scrollable */}
                <div className='flex justify-between items-center bg-sky-600 w-full text-gray-50 py-3 px-4 rounded-t-lg'>
                    <div className='invisible'>content</div>
                    <Heading heading={"Edit Channel"} />
                    <div className='self-center'>
                        <CloseIconButton action={handleClose} />
                    </div>
                </div>

                {/* Scrollable Modal Body */}
                <div className='modal-body overflow-auto px-6 py-4' style={{ maxHeight: 'calc(90vh - 60px)' }}>
                    <form onSubmit={formik.handleSubmit} className='flex flex-col gap-4'>
                        {/* Inputs */}
                        <div className='w-full'>
                            <InputBox
                                inputId='channelName'
                                inputLabel='Channel Name'
                                inputName='channelName'
                                inputValue={formik.values.channelName}
                                onChange={formik.handleChange}
                                customcss='w-full'
                            />
                        </div>

                        <div className='w-full'>
                            <InputBox
                                inputId='description'
                                inputLabel='Description'
                                inputName='description'
                                inputValue={formik.values.description}
                                onChange={formik.handleChange}
                                customcss='w-full'
                                multiline
                                rows={4}
                            />
                        </div>

                        {/* File Upload Section */}
                        <div className="form-group">
                            <label htmlFor="videoFile">Upload a 30-second Video:</label>
                            <input
                                id="videoFile"
                                name="videoFile"
                                type="file"
                                accept="video/*"
                                onChange={(event) => handleFileChange(event, 'videoFile')}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="logoFile">Upload Logo:</label>
                            <input
                                id="logoFile"
                                name="logoFile"
                                type="file"
                                accept="image/*"
                                onChange={(event) => handleFileChange(event, 'logoFile')}
                            />
                        </div>

                        {/* Logo Preview */}
                        {!formik.values.logoFile && channelData?.ChannelLogo && (
                            <div className='flex items-center justify-center h-40 w-60 mx-auto rounded-sm'>
                                <img
                                    alt='Channel Logo'
                                    src={`${process.env.REACT_APP_API_URL}/uploads/${channelData.ChannelLogo || FetchedChannelData?.commonChannelData?.ChannelLogo}`}
                                />
                            </div>
                        )}

                        {/* Video Preview */}
                        {!formik.values.videoFile && channelData?.ChannelVideo && (
                            <video className='h-40 w-60 mx-auto rounded-sm' controls>
                                <source src={`${process.env.REACT_APP_API_URL}/uploads/${channelData.ChannelVideo || FetchedChannelData?.commonChannelData?.ChannelVideo}`} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}

                        {/* Subscription and Visibility Select */}
                        <div className='my-2'>
                            <CustomSelectv2
                                options={[
                                    { id: 'basic', name: 'Basic' },
                                    { id: 'premium', name: 'Premium' },
                                    { id: 'enterprise', name: 'Enterprise' },
                                ]}
                                dataField="id"
                                dataText="name"
                                inputId="selectSubscriptionPlan"
                                label="Subscription Plan"
                                name="subscriptionPlan"
                                onChange={(selectedOption) => formik.setFieldValue("subscriptionPlan", selectedOption)}
                                value={formik.values.subscriptionPlan}
                            />
                        </div>

                        <div>
                            <SubscriptionPlanInfo
                                selectedPlan={selectedPlan}
                                setSelectedPlan={setSelectedPlan}
                                selectedCurrency={selectedCurrency}
                                setSelectedCurrency={setSelectedCurrency}
                                subscriptionAmount={subscriptionAmount}
                                setSubscriptionAmount={setSubscriptionAmount}
                            />
                        </div>

                        <div>
                            <CustomSelectv2
                                options={accessOptions}
                                dataField="value"
                                dataText="label"
                                inputId="Visibility"
                                label="Channel Visibility"
                                name="Visibility"
                                onChange={(selectedOption) => formik.setFieldValue("Visibility", selectedOption)}
                                value={formik.values.Visibility}
                            />
                        </div>

                        {/* Assessment and Series Select */}
                        <div>
                            <InputWithDropDownv2
                                data={combinedList}
                                dataField="id"
                                dataText="name"
                                inputId="selectCombined"
                                inputLabel="Select Assessment or Series"
                                inputName="selectedItem"
                                onChange={(event, value) => {
                                    formik.setFieldValue('selectedItem', value ? value.id : '');
                                    const selectassessmentList = value.filter(item => item.type === 'assessment').map(item => item.id.replace('assessment-', ''));
                                    const selectedseriesList = value.filter(item => item.type === 'series').map(item => item.id.replace('series-', ''));

                                    formik.setFieldValue('assessmentIdList', selectassessmentList);
                                    formik.setFieldValue('SeriesIdList', selectedseriesList);
                                    formik.setFieldValue('selectedassessmentData', value.map(item => item.id));
                                }}
                                inputValue={combinedList.filter(item => (formik.values.selectedassessmentData || []).includes(item.id)) || []}
                            />
                        </div>

                        {message && <p className="text-red-500">{message}</p>}

                        <Button type="submit" disabled={loading} variant='contained'>
                            {loading ? 'Submitting...' : 'Submit'}
                        </Button>
                    </form>
                </div>
            </div>
        </div>

    );
}

export default EditChannelModal;
