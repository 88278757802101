import React, { useEffect, useState } from 'react'
import StanderdConstants from '../../services/StanderdConstants';
import { InputBox } from '../../ButtonsIconsInputs/CustomInputs';
import ChannelPreview from '../assessment/ChannelPreview';
import GenericChannelCard from '../Channel/GenericChannelCard';
import { EditIconButton, LikeButton } from '../../ButtonsIconsInputs/IconButtons';
import DeleteConfirmation from '../reusableComp/Deletecomfirmation';

function ExploreChannels() {
    const [ChannelMasterData, setChannelMasterData] = useState([])
    const [error, setError] = useState(null);
    const [ShowChannelContent, setShowChannelContent] = useState(false)
    const [ChannelContent, setChannelContent] = useState([])
    const [ChannelData, setChannelData] = useState()
    const [filteredData, setfilteredData] = useState([]);
    const [query, setQuery] = useState('');

    const getdata = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/Channel/getPublicChannels`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: StanderdConstants.UserId })
            });
            const response = await result.json();
            setChannelMasterData(response.data);
            setfilteredData(response.data)


        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        getdata();
    }, []);


    const GetChannelContent = async (ChannelId) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessment_get/ChannelContent`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ChannelId })
            });
            const response = await result.json();
            setChannelContent(response.data);
            setChannelData(response.channelData)
            setShowChannelContent(true)

        } catch (error) {
            setError(error.message);
        }
    }
    const handleSearch = (e) => {
        console.log(query);
        console.log(e.target.value)
        const searchQuery = e.target.value.toLowerCase();
        setQuery(searchQuery);
        if (!searchQuery) {
            setfilteredData(ChannelMasterData);
        } else {
            const result = ChannelMasterData.filter(row =>
                Object.values(row) // Get all values from the object (row)
                    .some(value =>
                        value?.toString().toLowerCase().includes(searchQuery.toLowerCase()) // Check if any value contains the search query
                    )
            );
            console.log(result)
            setfilteredData(result);
        }
    }



    const MyActions = [
        {
            label: StanderdConstants.EDIT_LABEL,
            handler: () => { },
            tooltip: StanderdConstants.EDIT_LABEL,
            ButtonComponent: EditIconButton,
        },
        {
            label: StanderdConstants.DELETE_LABEL,
            handler: () => { },
            tooltip: StanderdConstants.DELETE_LABEL,
            ButtonComponent: DeleteConfirmation,

        },
        {
            label: 'like',
            handler: () => { },
            tooltip: 'like',
            ButtonComponent: LikeButton,

        }

    ];

    return (

        <div className='p-4'>
            {
                ShowChannelContent && (
                    <>
                        <div className="col-span-full mb-4 m-2">
                            <button
                                onClick={() => setShowChannelContent(false)}
                                className="inline-flex items-center px-4 py-2  bg-blue-500 text-white font-semibold text-sm rounded-md border-none shadow hover:bg-blue-600 transition duration-200"
                            >
                                ← Back
                            </button>
                        </div>
                        <ChannelPreview Data={ChannelContent} channelData={ChannelData} />

                    </>


                )
            }
            {!ShowChannelContent && <div>
                <div className="flex flex-row justify-start p-4 py-4  lg:w-1/5 md:2/5 ">
                    <InputBox
                        inputId='searchChannel'
                        inputLabel="Search Channels"
                        inputValueValue={query}
                        onChange={handleSearch}
                        className="border p-2 rounded-lg"
                        type="text"
                    />
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
                    {filteredData.map((item, index) => {
                        let badgeColor = "";
                        let borderColor = "";

                        // Define badge and border color based on AssignmentType
                        badgeColor = "bg-purple-500";
                        borderColor = "border-purple-300";

                        return (
                            <GenericChannelCard
                                title={item.ChannelName}
                                subtitle={item.CreaterName}
                                imageSrc={`${process.env.REACT_APP_API_URL}/AssessmentImages/${item.ChannelLogo}`}
                                badgeText="Channel"
                                badgeColor={badgeColor}
                                actions={MyActions}
                                item={item}
                                ShowChannelContent={() => GetChannelContent(item.ChannelId)}
                            />
                        );
                    })}


                </div>
            </div>}
        </div>
    )
}

export default ExploreChannels