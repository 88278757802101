import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import QuestionList from './QuestionList';
import { CustomButton } from '../../../ButtonsIconsInputs/IconButtons';
import { Save } from '@mui/icons-material';
import Heading from '../../reusableComp/Heading';
import StanderdConstants from '../../../services/StanderdConstants';

function ShowQuestions() {
    const api_url = process.env.REACT_APP_API_URL;
    const [Questions, setQuestions] = useState([]);
    const [ErrorMessage, setErorMessage] = useState('');
    const [ChangeOccured, setChangeOccured] = useState(false);
    const [Message, setMessage] = useState('');
    const [Assessmentset, setAssessmentset] = useState([]);
    const [assessmentType, setassessmentType] = useState('');
    const [AssessmentsetNumber, setAssessmentsetNumber] = useState(1);
    const [loading, setLoading] = useState(true); // Add loading state

    console.log(AssessmentsetNumber);
    const location = useLocation();
    const data = location.state?.data;
    console.log(data);

    const getQuestions = async () => {
        setLoading(true); // Set loading to true when starting the fetch
        try {
            const response = await fetch(`${api_url}/assessment_preview`, {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({ 'assessmentId': data }),
            });

            const result = await response.json();
            console.log(result);

            if (result.assessmentType === StanderdConstants.ASSESSMENTTYPESET) {
                setAssessmentset(result.selectedQuestions);
                setQuestions(result.selectedQuestions[0]);
            } else {
                setQuestions(result.selectedQuestions);
                setassessmentType(result.assessmentType);
            }

            if (!response.ok) {
                console.error("Error occurred: " + result.message);
                setErorMessage(result.message);
            }
        } catch (error) {
            console.error("Error fetching questions:", error);
            setErorMessage("Failed to load questions.");
        } finally {
            setLoading(false); // Set loading to false once data is fetched
        }
    };

    console.log(assessmentType);
    console.log(Questions);

    useEffect(() => {
        getQuestions();
    }, []);

    useEffect(() => {
        if (ChangeOccured) {
            getQuestions();
            setChangeOccured(false);
        }
    }, [ChangeOccured]);

    const ChangeSet = (Question, index) => {
        setQuestions(Question);
        setAssessmentsetNumber(index + 1);
    };

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <Heading heading='Question Preview' />

            {ErrorMessage && (<div className='text-red-600 text-center'>{ErrorMessage}</div>)}

            {loading ? ( // Conditional rendering for loading state
                <div className='flex justify-center items-center h-screen'>
                    <div className="text-gray-500 text-lg">...loading</div>
                </div>
            ) : (
                Questions && (
                    <div className="lg:w-3/4 mx-auto bg-white p-6 rounded-lg shadow-md">
                        <div className='flex flex-row gap-2 justify-between '>
                            {Assessmentset && Assessmentset.map((item, index) => (
                                <CustomButton
                                    key={index}
                                    onClick={() => ChangeSet(item, index)}
                                    Text={`set ${index + 1} `}
                                    customcss={`w-full my-2 ${(index + 1) === AssessmentsetNumber && `bg-yellow-500 transition-none`}`}
                                />
                            ))}
                        </div>

                        <QuestionList
                            data={Questions}
                            assessmentId={data}
                            setChangeOccured={setChangeOccured}
                            AssessmentSetNumber={AssessmentsetNumber || 1}
                            assessmentType={assessmentType}
                        />

                        <div className='flex justify-center '>
                            <CustomButton
                                onClick={() => setMessage('Assessment Saved')}
                                Text="Save Assessment"
                                customcss='w-full my-2'
                            />
                        </div>
                    </div>
                )
            )}

            <span className='block w-full text-yellow-500 text-center'>
                {Message && Message}
            </span>
        </div>
    );
}

export default ShowQuestions;
