import React, { useEffect, useState } from 'react'
import StanderdConstants from '../../services/StanderdConstants';
import { useNavigate } from 'react-router-dom';
import ChannelPreview from './ChannelPreview';
import { CustomButton } from '../../ButtonsIconsInputs/IconButtons';


function CandidateAssessmentUi() {
    const [MasterData, setMasterData] = useState([])
    const [AssessmentOfSeries, setAssessmentOfSeries] = useState([])
    const [error, setError] = useState(null);
    const [ShowAssessmentsOfseries, setShowAssessmentsOfseries] = useState(false)
    const [ShowChannelContent, setShowChannelContent] = useState(false)
    const [ChannelContent, setChannelContent] = useState([])
    const [ChannelData, setChannelData] = useState()

    const navigate = useNavigate();

    const getdata = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessment_get/AssessmentPanel`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: StanderdConstants.UserId })
            });
            const response = await result.json();
            setMasterData(response.data);

        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        getdata();
    }, []);

    const TakeAssessment = (assessmentId) => {
        navigate(`/assessment/`, { state: { data: assessmentId } })
    }

    const ViewAssessmentOfSeriesData = async (SeriesId) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessment_get/AssessmentOfSeries`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ SeriesId })
            });
            const response = await result.json();
            setAssessmentOfSeries(response.data);
            setShowAssessmentsOfseries(true)

        } catch (error) {
            setError(error.message);
        }
    }
    console.log(ShowAssessmentsOfseries)

    const GetChannelContent = async (ChannelId) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessment_get/ChannelContent`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ChannelId })
            });
            const response = await result.json();
            setChannelContent(response.data);
            setChannelData(response.channelData)
            setShowChannelContent(true)

        } catch (error) {
            setError(error.message);
        }
    }

    console.log(ChannelData)

    return (
        <div>
            {
                ShowChannelContent && (
                    <>
                        <div className="col-span-full mb-4">
                            <button
                                onClick={() => setShowChannelContent(false)}
                                className="inline-flex items-center px-4 py-2 border-none bg-blue-500 text-white font-semibold text-sm rounded-md border-none shadow hover:bg-blue-600 transition duration-200"
                            >
                                ← Back
                            </button>
                        </div>
                        <ChannelPreview Data={ChannelContent} channelData={ChannelData} />

                    </>


                )
            }
            {ShowAssessmentsOfseries && (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
                    {/* Back Button */}
                    <div className="col-span-full mb-4">
                        <button
                            onClick={() => setShowAssessmentsOfseries(false)}
                            className="inline-flex items-center px-4 py-2 bg-blue-500 border-none text-white font-semibold text-sm rounded-md shadow hover:bg-blue-600 transition duration-200"
                        >
                            ← Back
                        </button>
                    </div>

                    {/* Assessment Of Series */}
                    {AssessmentOfSeries.map((item, index) => (
                        <div key={index} className="border border-gray-200 rounded-lg overflow-hidden shadow-lg mb-4">
                            {/* Image */}
                            <img
                                className="w-full h-48 object-cover"
                                alt="assessment thumbnail"
                                src={`${process.env.REACT_APP_API_URL}/AssessmentImages/${item.AssessmentImage}`}
                            />
                            {/* Content */}
                            <div className="">
                                <h2 className="font-bold text-lg mb-2 bg-sky-600 text-gray-50 truncate">
                                    <span className="px-2">{item.nameOfAssessment}</span>
                                </h2>
                                <div className="flex flex-col text-gray-700 p-4">
                                    <span className="text-sm">Time: {item.Time}</span>
                                    <span className="text-sm">Total Marks: {item.TotalMarks}</span>
                                    <span className="text-sm">Questions: {item.numberOfQuestions}</span>
                                    <CustomButton Text='Start Assessment' onClick={() => TakeAssessment(item.assessmentId)} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            )}

            {/* Master Data Section */}
            {!ShowAssessmentsOfseries && !ShowChannelContent && (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
                    {MasterData.map((item, index) => {
                        let badgeColor = "";
                        let borderColor = "";

                        // Define badge and border color based on AssignmentType
                        switch (item.AssignmentType) {
                            case "assessment":
                                badgeColor = "bg-blue-500";
                                borderColor = "border-blue-300";
                                break;
                            case "series":
                                badgeColor = "bg-green-500";
                                borderColor = "border-green-300";
                                break;
                            case "channel":
                                badgeColor = "bg-purple-500";
                                borderColor = "border-purple-300";
                                break;
                            default:
                                badgeColor = "bg-gray-500";
                                borderColor = "border-gray-300";
                        }

                        return (
                            <div
                                key={index}
                                className={`relative max-w-xs rounded-lg overflow-hidden shadow-lg border ${borderColor} transition-transform transform hover:scale-105`}
                            >
                                {/* Badge */}
                                <div className={`absolute top-2 right-2 text-white text-xs font-semibold px-2 py-1 rounded-lg ${badgeColor}`}>
                                    {item.AssignmentType.charAt(0).toUpperCase() + item.AssignmentType.slice(1)}
                                </div>

                                {/* Image */}
                                <img
                                    className="w-full h-48 object-cover"
                                    alt={
                                        item.AssignmentType === StanderdConstants.ASSIGNMENTTYPEASSESSMENT
                                            ? "assessment banner"
                                            : item.AssignmentType === StanderdConstants.ASSIGNMENTTYPESERIES
                                                ? "series banner"
                                                : "channel logo"
                                    }
                                    src={`${process.env.REACT_APP_API_URL}/AssessmentImages/${item.AssignmentType === "assessment"
                                        ? item.AssessmentImage
                                        : item.AssignmentType === StanderdConstants.ASSIGNMENTTYPESERIES
                                            ? item.SeriesImage
                                            : item.ChannelLogo
                                        }`}
                                />

                                {/* Content */}
                                <div>
                                    {item.AssignmentType === StanderdConstants.ASSIGNMENTTYPEASSESSMENT && (
                                        <div className="">
                                            <h2 className="font-bold text-lg mb-2 bg-sky-600 text-gray-50 truncate">
                                                <span className="px-2">{item.nameOfAssessment}</span>
                                            </h2>
                                            <div className="flex flex-col text-gray-700 p-4">
                                                <span className="text-sm">Time: {item.Time}</span>
                                                <span className="text-sm">Total Marks: {item.TotalMarks}</span>
                                                <span className="text-sm">Questions: {item.numberOfQuestions}</span>
                                                <CustomButton Text='Start Assessment' onClick={() => TakeAssessment(item.assessmentId)} />

                                            </div>
                                        </div>
                                    )}

                                    {item.AssignmentType === StanderdConstants.ASSIGNMENTTYPESERIES && (
                                        <div className="">
                                            <h2
                                                className="font-bold text-lg mb-2 bg-sky-600 text-gray-50 truncate hover:cursor-pointer"
                                                onClick={() => ViewAssessmentOfSeriesData(item.AssessmentSeriesId)}
                                            >
                                                <span className="px-2">{item.AssessmentSeriesName}</span>
                                            </h2>
                                        </div>
                                    )}

                                    {item.AssignmentType === StanderdConstants.ASSIGNMENTTYPECHANNEL && (
                                        <div className="">
                                            <h2 className="font-bold text-lg mb-2 bg-sky-600 text-gray-50 truncate hover:cursor-pointer" onClick={() => GetChannelContent(item.ChannelId)}>
                                                <span className="px-2">{item.ChannelName}</span>
                                            </h2>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );


}

export default CandidateAssessmentUi