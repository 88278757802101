import React, { useEffect, useState } from 'react';
import { InputBox } from '../../ButtonsIconsInputs/CustomInputs'; // Custom input component
import MUIRichTextEditor from 'mui-rte';
import { useFormik } from 'formik';
import { ContentState, convertFromHTML, convertFromRaw, convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import StanderdConstants from '../../services/StanderdConstants';
import { CheckCircle } from '@mui/icons-material';
import Papa from 'papaparse';
import * as Yup from 'yup';

function Invite() {
    const [emailTemplate, setEmailTemplate] = useState(null);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [emailBody, setEmailBody] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [EmailSentStatus, SetEmailSentStatus] = useState(false);
    const { state } = useLocation();
    console.log(state.Assessment)
    // Fetch email template data
    const getEmailTemplate = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/Invitation/EmailTemplate/?Id=${StanderdConstants.INVITECANDIDATEFORASSESSMENT}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            const result = await response.json();
            setEmailTemplate(result.data);
        } catch (error) {
            setError(error.message);
        }
    };



    useEffect(() => {
        getEmailTemplate();
    }, []);

    const formik = useFormik({
        initialValues: {
            Title: '',
            subject: '',
            recipients: [],
            useUniqueUrl: 'yes',
        },
        validationSchema: Yup.object({
            recipients: Yup.array()
                .of(Yup.string().email('Invalid email format'))
                .required('Emails are required')
        }),
        onSubmit: (values) => {
            const contentState = convertFromRaw(JSON.parse(emailBody || '{}'));
            const htmlContent = stateToHTML(contentState);

            sendInvite({
                Title: values.Title,
                subject: values.subject,
                Type: state.Assessment.Type,
                recipients: values.recipients,
                useUniqueUrl: values.useUniqueUrl,
                Id: state.Assessment.assessmentId || state.Assessment.SeriesId || state.Assessment.ChannelId,
                EmailBody: htmlContent,
            });
        },
    });

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    useEffect(() => {
        if (selectedFile) {
            handleCSVUpload();
        }
    }, [selectedFile]);

    const handleCSVUpload = () => {
        Papa.parse(selectedFile, {
            header: true,
            complete: (result) => {
                const emails = result.data.map(row => row.Email).filter(Boolean);
                formik.setFieldValue('recipients', emails);
            },
            error: (error) => {
                console.error('Error parsing CSV:', error);
            }
        });
    };

    useEffect(() => {
        if (emailTemplate) {
            const blocksFromHTML = convertFromHTML(emailTemplate.Body);
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            const rawContentState = convertToRaw(contentState);

            formik.setValues({
                Title: emailTemplate.Title || '',
                subject: emailTemplate.Subject || '',
                recipients: formik.values.recipients,
                useUniqueUrl: formik.values.useUniqueUrl,
            });

            setEmailBody(JSON.stringify(rawContentState));
        }
    }, [emailTemplate]);

    const handleChangeRichTextBox = (data) => {
        setEmailBody(data);
    };

    const sendInvite = async (data) => {
        try {
            SetEmailSentStatus(true)
            const response = await fetch(`${process.env.REACT_APP_API_URL}/Invitation/SendInvite/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            });
            const result = await response.json();
            if (response.ok) {
                SetEmailSentStatus(false)
            }
            else {
                setMessage(result.message)
                SetEmailSentStatus(false)
            }
            setMessage(result.message);
        } catch (error) {
            setError(error.message);
        }
    };

    console.log(formik.errors)

    return (
        <form onSubmit={formik.handleSubmit} className='p-8 flex-col gap-6 px-16'>
            <div className='bg-sky-600 py-2 text-center text-gray-50'>
                <h1 className='text-2xl rounded-sm'>Invitations for {state.Assessment.nameOfAssessment || state.Assessment.SeriesName || state.Assessment.ChannelName}</h1>
                <span className='text-sm text-right'>Invite new candidates and track invitations</span>
            </div>

            <div>
                <h1 className='text-md'>Use unique URL for each candidate?</h1>
                <div className='flex flex-row gap-2'>
                    {['yes', 'no'].map((value) => (
                        <div key={value} className={`flex flex-col w-1/2 border-2 p-2 rounded-md ${formik.values.useUniqueUrl === value ? 'border-green-500' : ''}`}>
                            <label className='hover:cursor-pointer mx-4'>
                                <input
                                    type="radio"
                                    name="useUniqueUrl"
                                    value={value}
                                    hidden
                                    checked={formik.values.useUniqueUrl === value}
                                    onChange={formik.handleChange}
                                />
                                <span className='flex justify-between my-2'>
                                    <span className='text-md capitalize'>{value}</span>
                                    <span>{formik.values.useUniqueUrl === value ? <CheckCircle className='text-green-500' /> : <span className='invisible'>hello</span>}</span>
                                </span>
                                <div className='text-sm'>
                                    {value === 'yes'
                                        ? 'Each candidate is emailed a single-use URL. Helps prevent assessments from being shared.'
                                        : 'Directly share the URL with all candidates. Helps share in job posts, WhatsApp, etc.'}
                                </div>
                            </label>
                        </div>
                    ))}
                </div>
            </div>

            <div>
                <h1 className='text-md'>Send Email</h1>
                <span className='text-sm'>Add recipients, review the email body, and click send.</span>
            </div>

            <div className='flex flex-col gap-9'>
                <InputBox
                    inputId='Title'
                    inputLabel='Title'
                    inputName='Title'
                    onChange={formik.handleChange}
                    inputValue={formik.values.Title}
                />

                <InputBox
                    inputId='mail-subject'
                    inputLabel='Subject'
                    inputName='subject'
                    onChange={formik.handleChange}
                    inputValue={formik.values.subject}
                />

                <div>
                    <label className='text-sm'>Type comma-separated email addresses below or upload a CSV file</label>
                    <InputBox
                        inputLabel='Recipients'
                        inputName='recipients'
                        multiline={true}
                        onChange={(e) => {
                            const emailArray = e.target.value.split(',').map(email => email.trim());
                            formik.setFieldValue('recipients', emailArray);
                        }}
                        inputValue={formik.values.recipients}  // Join array back to string for display
                    />

                    <div className="flex justify-center my-4">
                        <span className="text-center w-full">or</span>
                    </div>

                    <div className='flex justify-center my-4'>
                        <span className='text-center'>
                            <label className='hover:cursor-pointer hover:text-blue-700 hover:underline'>
                                <input
                                    type='file'
                                    accept='.csv'
                                    hidden
                                    onChange={handleFileChange}
                                />
                                <h1>Upload A CSV File</h1>
                            </label>

                            <a href={`${process.env.REACT_APP_API_URL}/StaticFiles/sample_names_email.csv`} download >
                                <h1 className='hover:text-blue-700 hover:underline'>Download Sample Template For CSV</h1>
                            </a>
                        </span>
                    </div>
                </div>

                <div>
                    <label className='text-sm'>Email Body</label>
                    <MUIRichTextEditor
                        defaultValue={emailBody || ''} // Avoid flickering
                        onSave={handleChangeRichTextBox}
                        inlineToolbar={true}
                    />
                </div>
                {message && message}
                {<Button variant='contained' disabled={EmailSentStatus} type='submit'>{EmailSentStatus ? 'Sending Invite...' : 'Send invite'}</Button>
                }            </div>
        </form>
    );
}

export default Invite;
