import React, { useEffect, useState } from 'react';
import { CustomSelect, CustomSelectv2, InputWithDropDown, InputWithDropDownv2 } from '../../ButtonsIconsInputs/CustomInputs';
import { CloseIconButton } from '../../ButtonsIconsInputs/IconButtons';
import Heading from '../reusableComp/Heading';
import { Button, Chip } from '@mui/material';
import { useFormik } from 'formik';
import StanderdConstants from '../../services/StanderdConstants';


function AddModal({ handleclose, setStatus }) {
    const [AssessmentList, SetAssessmentList] = useState([]);
    const [AssessmentSeriesList, setAssessmentSeriesList] = useState([]);
    const [ChannelList, setChannelList] = useState([]);
    const [CandidateGroupData, setCandidateGroupData] = useState([])
    const [usersData, setUsersData] = useState([[]]);
    const [message, setmessage] = useState([]);
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            assessmentId: '',
            SeriesId: '',
            ChannelId: '',
            usersIdArray: [],
            groupIdArray: [],
            Type: ''
        },

        onSubmit: values => {
            console.log(values);
            saveData(values);
        }
    });
    console.log(formik.values)
    const saveData = async (data) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessmentallocation/Add`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            });
            const response = await result.json();
            if (result.ok) {
                setStatus(true);
                SetAssessmentList(response.data);
            }

        } catch (error) {
            setmessage(error.message);
        }
    }

    const getAssessments = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessment_get`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: StanderdConstants.UserId })
            });
            const response = await result.json();
            if (result.ok) {
                SetAssessmentList(response.assessments);
            }
        } catch (error) {
            setmessage(error.message);
        }
    };

    useEffect(() => {
        getAssessments();
    }, []);

    const getAssessmentSeriesData = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessmentseries/getseriesNames`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: StanderdConstants.UserId })
            });
            const response = await result.json();
            if (result.ok) {
                setAssessmentSeriesList(response.data);
            }
        } catch (error) {
            setmessage(error.message);
        }
    };

    useEffect(() => {
        getAssessmentSeriesData();
    }, []);

    const getChannelData = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/Channel/getChannelIdNames`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: StanderdConstants.UserId })
            });
            const response = await result.json();
            if (result.ok) {
                setChannelList(response.data);
            }
        } catch (error) {

            setmessage(error.message);
        }
    };

    useEffect(() => {
        getChannelData();
    }, []);


    const getCandidateGroupData = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/CandidateGroup/getGroupData`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: StanderdConstants.UserId })
            });
            const response = await result.json();
            if (result.ok) {
                setCandidateGroupData(response.data);
            }
        } catch (error) {
            setmessage(error.message);
        }
    };



    useEffect(() => {
        getCandidateGroupData();
    }, []);

    const getUsers = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/manage/getData`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ tablename: StanderdConstants.TABLENAME_USERS })
            });
            const response = await result.json();
            if (result.ok) {
                setUsersData(response.data);
            }
        } catch (error) {
            setmessage(error.message);
        }
    };

    useEffect(() => {
        getUsers();
    }, []);


    console.log(formik.values.Type)

    const combinedCandidateList = [
        ...(Array.isArray(usersData) ? usersData.map(user => ({
            id: `individual-${user.Id}`, // Prefix the ID with 'individual'
            name: user.Name,
            type: 'individual'
        })) : []),

        ...(Array.isArray(CandidateGroupData) ? CandidateGroupData.map(group => ({
            id: `group-${group.CandidateGroupId}`, // Prefix the ID with 'group'
            name: group.CandidateGroupName,
            type: 'group'
        })) : [])
    ];

    const combinedList = [
        ...(Array.isArray(AssessmentList) ? AssessmentList.map(assessment => ({
            id: assessment.assessmentId,
            name: assessment.nameOfAssessment,
            type: 'assessment'
        })) : []), // Ensure AssessmentList is an array

        ...(Array.isArray(AssessmentSeriesList) ? AssessmentSeriesList.map(series => ({
            id: series.AssessmentSeriesId,
            name: series.AssessmentSeriesName,
            type: 'series'
        })) : []) // Ensure AssessmentSeriesList is an array
        ,
        ...(Array.isArray(ChannelList) ? ChannelList.map(Channel => ({
            id: Channel.ChannelId,
            name: `CHANNEL- ${Channel.ChannelName}`, // Corrected 'chaneel' to 'channel'
            type: 'channel'
        })) : [])// Ensure AssessmentSeriesList is an array

    ]
    console.log(combinedList)


    return (
        <div className='modal-overlayy'>
            <div className='modal-contentt'>
                <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3 px-4'>
                    <div className='invisible'>content</div>
                    <Heading heading={"Assign assessments to candidates"} />
                    <div className='self-center'>
                        <CloseIconButton action={handleclose} />
                    </div>
                </div>

                <form className='flex flex-col gap-10 px-5 mb-5' onSubmit={formik.handleSubmit}>

                    <div>
                        <InputWithDropDown
                            data={combinedList}
                            dataField="id"
                            dataText="name"
                            inputId="selectCombined"
                            inputLabel="Select Assessment or Series"
                            inputName="selectedItem"
                            onChange={(event, value) => {
                                formik.setFieldValue('selectedItem', value ? value.id : '');
                                if (value) {
                                    if (value.type === 'assessment') {
                                        formik.setFieldValue('assessmentId', value.id);
                                        formik.setFieldValue('SeriesId', '');
                                        formik.setFieldValue('ChannelId', '')
                                    } else if (value.type == 'series') {
                                        formik.setFieldValue('SeriesId', value.id);
                                        formik.setFieldValue('assessmentId', '');
                                        formik.setFieldValue('ChannelId', '')

                                    } else {
                                        formik.setFieldValue('SeriesId', '');
                                        formik.setFieldValue('assessmentId', '');
                                        formik.setFieldValue('ChannelId', value.id)
                                    }
                                }
                            }}
                            inputValue={combinedList?.find(item => item?.id === formik?.values?.selectedItem) || null} // Update this based on selectedItem
                        />
                    </div>



                    <div>
                        <InputWithDropDownv2
                            data={combinedCandidateList}
                            dataField="id"
                            dataText="name"
                            inputId="selectCandidate"
                            inputLabel="Select Candidate"
                            inputName='selectedCandidate'
                            multiple={true}
                            onChange={(event, newValue) => {
                                const selectedIndividualIds = newValue
                                    .filter(item => item.type === 'individual')
                                    .map(item => item.id.replace('individual-', ''));

                                const selectedGroupIds = newValue
                                    .filter(item => item.type === 'group')
                                    .map(item => item.id.replace('group-', ''));
                                formik.setFieldValue('usersIdArray', selectedIndividualIds);
                                formik.setFieldValue('groupIdArray', selectedGroupIds);
                                formik.setFieldValue('selectedCandidatedata', newValue.map(item => item.id)); // Store full ID with prefixes
                            }}
                            inputValue={combinedCandidateList.filter(item =>
                                (formik.values.selectedCandidatedata || []).includes(item.id)
                            ) || []}
                        />

                    </div>

                    {message && <div>{message}</div>}
                    <Button variant='contained' className='mt-4' id='addCompany' type='submit'>
                        Save
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default AddModal;
