import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const DataTable = ({ actions, data }) => {
    const [loading, setLoading] = useState(true); // State to manage loading state
    const [error, setError] = useState(null); // State to manage error messages
    const rowsPerPage = 10;
    const [page, setPage] = useState(1);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const paginatedData = data?.slice(startIndex, endIndex);



    useEffect(() => {
        const totalPages = Math.ceil(data.length / rowsPerPage);
        if (page > totalPages && totalPages > 0) {
            setPage(totalPages);
        } else if (data.length === 0) {
            setPage(1);
        }
    }, [data, page, rowsPerPage]);



    return (
        <div className="overflow-x-auto">
            {paginatedData?.length > 0 ? (
                <>
                    <table className="w-full border-collapse border border-gray-200 overflow-x-auto">
                        <thead>
                            <tr className="text-gray-50 bg-sky-600">
                                <th className="border p-2 text-start min-w-[150px]">Channel Name</th>
                                <th className="border p-2 min-w-[250px]">Channel Description</th>
                                <th className="border p-2 min-w-[250px]">Channel Content</th>

                                <th className="border p-2 min-w-[150px]">Subscription ID</th>
                                <th className="border p-2 min-w-[100px]">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData?.map((item, index) => (
                                <tr key={index} className="hover:bg-gray-50">
                                    <td className="border p-2 min-w-[150px]">
                                        {item.ChannelName}
                                    </td>
                                    <td className="border p-2 min-w-[250px]">
                                        <span>{item.ChannelDescription}</span>
                                    </td>
                                    <td className="border p-2 min-w-[250px]">
                                        <div>
                                            <strong>Series:</strong> {item.Series.map(series => series.AssessmentSeriesName).join(", ")}
                                        </div>
                                        <div>
                                            <strong>Assessments:</strong> {item.Assessments.map(assessment => assessment.nameOfAssessment).join(", ")}
                                        </div>
                                    </td>


                                    <td className="border p-2 min-w-[150px]">
                                        {item.SubscriptionId}
                                    </td>

                                    {actions?.length > 0 && (
                                        <td className="border p-2 min-w-[100px]">
                                            <div className="flex justify-around">
                                                {actions.map((action, actionIndex) => {
                                                    const ButtonComponent = action.ButtonComponent;
                                                    return (
                                                        <ButtonComponent
                                                            key={actionIndex}
                                                            action={() => action.handler(item, index)}
                                                            tooltip={action.tooltip}
                                                            id={index}
                                                            itemName={item.ChannelName}
                                                            itemData={item}
                                                            label={action.label}
                                                            className={action.className}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <div className="flex justify-center items-center m-auto p-6 bg-gray-100 text-gray-500 rounded-lg shadow-md">
                    <p className="text-lg font-semibold">No Data Found</p>
                </div>
            )}
            <div className="flex justify-center mt-4">
                <Stack spacing={2}>
                    <Pagination
                        count={Math.ceil(data.length / rowsPerPage)}
                        page={page}
                        onChange={handleChange}
                        color="primary"
                    />
                </Stack>
            </div>
        </div>
    );
};

export default DataTable;
