import React, { useEffect, useState } from 'react';
import DonutChartsRow from './DonutChartsRow';
import RadarChart from './RadarChart';
import SkillsTable from './SkillsTable';
import DownloadReport from '../DownlodReport';
import Competency from '../../../competency/Competency';
import { useLocation } from 'react-router-dom';
import StanderdConstants from '../../../../services/StanderdConstants';

function ShowCharts() {
    const [chartData, setChartData] = useState([])
    const [Base, setBase] = useState([])
    const [CompData, setCompData] = useState([])
    const [skillData, setSkillsData] = useState([])
    const [JobroleName, setJobroleName] = useState('')

    const location = useLocation()
    console.log(location.state)
    const AssessmentAttemptId = location.state?.data?.AssessmentAttemptId;

    const getChartData = async () => {
        let result = await fetch(`${process.env.REACT_APP_API_URL}/assessmentResults/chartsData/?id=${AssessmentAttemptId}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
        });
        result = await result.json();
        setChartData(result.data);
        setBase(result.base)
        setJobroleName(result.jobroleName)
    }
    useEffect(() => {
        getChartData()
    }, [])


    const processCompetencyData = (chartData) => {
        const uniqueCompetencyQuestions = new Set();

        // Step 1: Filter unique competency-question pairs
        const filteredResults = chartData.filter(item => {
            const pair = `${item.competencyId}-${item.questionId}`;
            if (!uniqueCompetencyQuestions.has(pair)) {
                uniqueCompetencyQuestions.add(pair);
                return true;
            }
            return false;
        });

        // Step 2: Aggregate the competency data
        const CompetencyData = [];
        filteredResults.forEach(item => {
            const competencyIndex = CompetencyData.findIndex(cd => cd.Id === item.competencyId);
            if (competencyIndex === -1) {
                CompetencyData.push({
                    Id: item.competencyId,
                    Name: item.competencyName,
                    occurrence: 1,
                    NumberOfQuestion: item.numberOfQuestions,
                    obtainedMarks: item.Score || 0,
                    TotalMarks: item.TotalMarks
                });
            } else {
                CompetencyData[competencyIndex].occurrence += 1;
                CompetencyData[competencyIndex].obtainedMarks += item.Score || 0;
            }
        });

        // Step 3: Update competency data based on occurrence and calculation
        const updatedCompetencyData = CompetencyData.map(item => ({
            ...item,
            obtainedMarks: Math.round(item.obtainedMarks * (item.NumberOfQuestion / item.TotalMarks)),
            TotalMarks: Math.round(item.occurrence * (item.NumberOfQuestion / item.TotalMarks))
        }));

        return updatedCompetencyData;
    };


    const processSkillData = (chartData) => {
        const uniqueCompetenySkills = new Set();

        // Step 1: Filter unique skill-question pairs
        const filteredSkills = chartData.filter(item => {
            const pair = `${item.skillId}-${item.questionId}`;
            if (!uniqueCompetenySkills.has(pair)) {
                uniqueCompetenySkills.add(pair);
                return true;
            }
            return false;
        });

        // Step 2: Aggregate the skill data
        const SkillData = [];
        filteredSkills.forEach(item => {
            const skillIndex = SkillData.findIndex(sd => sd.Id === item.skillId);
            if (skillIndex === -1) {
                SkillData.push({
                    Id: item.skillId,
                    Name: item.skillName,
                    occurrence: 1,
                    NumberOfQuestion: item.numberOfQuestions,
                    obtainedMarks: item.Score || 0,
                    TotalMarks: item.TotalMarks
                });
            } else {
                SkillData[skillIndex].occurrence += 1;
                SkillData[skillIndex].obtainedMarks += item.Score || 0;
            }
        });

        // Step 3: Update skill data based on occurrence and calculation
        const updatedSkillData = SkillData.map(item => ({
            ...item,
            obtainedMarks: Math.round(item.obtainedMarks * (item.NumberOfQuestion / item.TotalMarks)),
            TotalMarks: Math.round(item.occurrence * (item.NumberOfQuestion / item.TotalMarks))
        }));

        return updatedSkillData;
    };
    console.log(Base)

    useEffect(() => {
        if (Base == StanderdConstants.ASSESSMENT_BASISS_COMPETENCY || StanderdConstants.ASSESSMENT_BASISS_JOBROLE) {
            const updateCompetencyData = processCompetencyData(chartData)
            setCompData(updateCompetencyData)
        }

        const updateSkillData = processSkillData(chartData)
        setSkillsData(updateSkillData)


    }, [chartData])


    console.log(CompData)
    console.log(skillData)

    return (
        <div className="flex flex-col gap-2">
            <div className='text-center text-xl'>Assessment Report</div>
            <div className='flex justify-end'><DownloadReport /></div>
            <div>
                <DonutChartsRow Chartdata={(Base == StanderdConstants.ASSESSMENT_BASISS_COMPETENCY || Base == StanderdConstants.ASSESSMENT_BASISS_JOBROLE) ? CompData : skillData} Base={Base} Name={JobroleName} />
            </div>

            <div className="flex flex-row justify-between gap-2 ">
                {Base !== 'skills' && <div className="">
                    <SkillsTable tableData={skillData} />
                </div>}
                <div className=" w-fit ">
                    <RadarChart />
                </div>

            </div>
        </div>
    );
}

export default ShowCharts;
