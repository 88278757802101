import React, { useState } from 'react';
import { AppBar, Tabs, Tab, Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { CustomButton } from '../../ButtonsIconsInputs/IconButtons';
import { useNavigate } from 'react-router-dom';
import Subscription from '../reusableComp/Subscription';

// Helper component for tab panels
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};




function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ChannelPreview({ Data, channelData }) {
    const [assessment, setAssessment] = useState(Data?.assessment || []); // Initialize state for assessments
    const [series, setSeries] = useState(Data?.series || []);
    const [value, setValue] = React.useState(0);
    const [AssessmentOfSeries, setAssessmentOfSeries] = useState([])
    const [ShowAssessmentsOfseries, setShowAssessmentsOfseries] = useState(false)
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const handleChange = (event, newValue) => {
        setValue(newValue);

    };

    const TakeAssessment = (assessmentId) => {
        navigate(`/assessment/`, { state: { data: assessmentId } })
    }
    console.log(channelData)

    const ViewAssessmentOfSeriesData = async (SeriesId) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessment_get/AssessmentOfSeries`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ SeriesId })
            });
            const response = await result.json();
            setAssessmentOfSeries(response.data);
            setShowAssessmentsOfseries(true)

        } catch (error) {
            setError(error.message);
        }
    }
    const renderAssessmentCards = () => (

        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 py-2'>
            {assessment.map((assessment, index) => (
                <div key={index} className="border border-gray-200 rounded-lg overflow-hidden shadow-lg mb-4">
                    {/* Image */}
                    <img
                        className="w-full h-48 object-cover"
                        alt="assessment thumbnail"
                        src={`${process.env.REACT_APP_API_URL}/AssessmentImages/${assessment.AssessmentImage}`}
                    />
                    {/* Content */}
                    <div>
                        <h2 className="font-bold text-lg mb-2 bg-sky-600 text-gray-50 truncate">
                            <span className="px-2">{assessment.nameOfAssessment}</span>
                        </h2>
                        <div className="flex flex-col text-gray-700 p-4">
                            <span className="text-sm">Time: {assessment.Time}</span>
                            <span className="text-sm">Total Marks: {assessment.TotalMarks}</span>
                            <span className="text-sm">Questions: {assessment.numberOfQuestions}</span>
                            <CustomButton Text='Start Assessment' onClick={() => TakeAssessment(assessment.assessmentId)} />
                            <span className=' flex justify-left m-2'>
                                <Subscription
                                    Type='assessment'
                                    Id={assessment.assessmentId}
                                    SubscriptionAmount='0'
                                    SubscriptionPlanTypeId='Free'


                                />
                            </span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    // Function to render series cards
    const renderSeriesCards = () => (
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4'>
            {series.map((seriesItem, index) => (
                <div key={index} className="border border-gray-200 rounded-lg overflow-hidden shadow-lg mb-4">
                    {/* Image */}
                    <img
                        className="w-full h-48 object-cover"
                        alt="series thumbnail"
                        src={`${process.env.REACT_APP_API_URL}/AssessmentImages/${seriesItem.SeriesImage}`}
                    />
                    {/* Content */}
                    <div>
                        <h2 className="font-bold text-lg mb-2 bg-sky-600 text-gray-50 truncate hover:cursor-pointer"
                            onClick={() => ViewAssessmentOfSeriesData(seriesItem.AssessmentSeriesId)}

                        >
                            <span className="px-2">{seriesItem.AssessmentSeriesName}</span>
                        </h2>
                        <div className="flex flex-col text-gray-700 p-4">
                            <span className="text-sm">Status ID: {seriesItem.StatusId}</span>
                        </div>
                        <span className=' flex justify-left m-2'>
                            <Subscription
                                Type='series'
                                Id={seriesItem.AssessmentSeriesId}
                                SubscriptionAmount='0'
                                SubscriptionPlanTypeId='Free'

                            />
                        </span>
                    </div>
                </div>
            ))}
        </div>
    );

    return (

        <div>
            <div className="bg-gray-100  flex flex-col items-center">
                <header className="bg-white shadow-md w-full p-4 border rounded-md flex items-center max-w-7xl">
                    <img
                        src={`${process.env.REACT_APP_API_URL}/AssessmentImages/${channelData.ChannelLogo}`}
                        alt="Channel Logo"
                        className="w-16 h-16 rounded-full mr-4"
                    />
                    <h1 className="text-2xl font-bold">{channelData.ChannelName}</h1>
                    <div className="ml-auto flex items-center space-x-4">
                        {/* Subscription Status (Green badge) */}
                        <span className="bg-green-500 text-white font-semibold py-2 px-3 rounded self-baseline">
                            {channelData.SubscriptionId.charAt(0).toUpperCase() + channelData.SubscriptionId.slice(1)}
                        </span>

                        {/* Subscription Button */}
                        <span className='flex justify-center self-baseline'>
                            <Subscription
                                Type='channel'
                                Id={channelData?.ChannelId}
                                SubscriptionAmount='0'
                                SubscriptionPlanTypeId='Free'
                            />
                        </span>
                    </div>

                </header>

                {/* Channel Description */}
                <section className="max-w-7xl w-full p-4">
                    <p className="text-gray-700 text-lg mb-4">{channelData.ChannelDescription}</p>
                </section>
            </div>
            {
                ShowAssessmentsOfseries &&
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-2        ">
                    {/* Back Button */}
                    <div className="col-span-full mb-4">
                        <button
                            onClick={() => setShowAssessmentsOfseries(false)}
                            className="inline-flex border-none items-center px-4 py-2 bg-blue-500 text-white font-semibold text-sm rounded-md shadow hover:bg-blue-600 transition duration-200"
                        >
                            ← Back
                        </button>
                    </div>

                    {/* Assessment Of Series */}
                    {AssessmentOfSeries.map((item, index) => (
                        <div key={index} className="border border-gray-200 rounded-lg overflow-hidden shadow-lg mb-4">
                            {/* Image */}
                            <img
                                className="w-full h-48 object-cover"
                                alt="assessment thumbnail"
                                src={`${process.env.REACT_APP_API_URL}/AssessmentImages/${item.AssessmentImage}`}
                            />
                            {/* Content */}
                            <div className="">
                                <h2 className="font-bold text-lg mb-2 bg-sky-600 text-gray-50 truncate">
                                    <span className="px-2">{item.nameOfAssessment}</span>
                                </h2>
                                <div className="flex flex-col text-gray-700 p-4">
                                    <span className="text-sm">Time: {item.Time}</span>
                                    <span className="text-sm">Total Marks: {item.TotalMarks}</span>
                                    <span className="text-sm">Questions: {item.numberOfQuestions}</span>
                                    <CustomButton Text='Start Assessment' onClick={() => TakeAssessment(item.assessmentId)} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }
            {!ShowAssessmentsOfseries && <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Assessments" {...a11yProps(0)} />
                        <Tab label="Series" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    {/* Call the function to render assessment cards */}
                    {renderAssessmentCards()}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    {/* Call the function to render series cards */}
                    {renderSeriesCards()}
                </CustomTabPanel>
            </Box>}
        </div>
    );
}
