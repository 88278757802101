import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { Menu, PermIdentity, PowerSettingsNewOutlined } from '@mui/icons-material';

function Header({ toggled, setToggled }) {
    const navigate = useNavigate();
    const location = useLocation();
    const isAuthPage = location.pathname === '/login' || location.pathname === '/signup';

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        navigate('/');
    };

    return (
        <div className='w-full bg-sky-600 grid grid-cols-3 items-center py-3 z-50'>
            <IconButton
                onClick={() => setToggled(true)} // This will open the sidebar
                className={`${toggled ? 'invisible' : ''} text-gray-50  justify-self-start`}            >
                <Menu />
            </IconButton>
            <h1 className='text-2xl text-center text-gray-50 col-span-1'>Wise Assessment</h1>
            <div className='m-0 flex justify-end'>
                {!isAuthPage && localStorage.getItem('userId') ? (
                    <IconButton
                        className='border-none'
                        onClick={logout}
                        title='logout'
                    >
                        <PowerSettingsNewOutlined className='text-gray-50 font-extralight' />
                    </IconButton>
                ) : (
                    <div className='border p-1 px-2 rounded-3xl invisible'>

                    </div>
                )}
            </div>
        </div >
    );
}

export default Header;
