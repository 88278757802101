import React from 'react';
import { Divider } from '@mui/material';
import { CloseIconButton } from '../../ButtonsIconsInputs/IconButtons';

function ImageUpload({ images, onRemove }) {
    return (
        <div className="flex  flex-wrap gap-4">
            {images.map((image, index) => (
                <div key={index} className="relative ">
                    <div className='flex '>
                        <img src={URL.createObjectURL(image)} alt={`uploaded ${index}`} className="w-32 h-32 object-cover rounded-md" />
                        <CloseIconButton
                            action={() => onRemove(index)}
                            iconColor='text-gray-700 h-10'
                        />

                    </div>

                </div>

            ))}
            <Divider orientation='vertical' />
        </div>
    );
}

export default ImageUpload;
