import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Heading from '../reusableComp/Heading';
import { CloseIconButton } from '../../ButtonsIconsInputs/IconButtons';
import { CustomSelectv2, CustomSelectv4, InputBox, InputWithDropDownv2 } from '../../ButtonsIconsInputs/CustomInputs';
import { Button } from '@mui/material';
import StanderdConstants from '../../services/StanderdConstants';
import { checkIfUserBelongsToOrganization, getAccessOptions } from '../reusableComp/IsOrganisation';
import SubscriptionPlanInfo from '../reusableComp/SubscriptionPlanInfo';

const subscriptionPlans = [
    { id: 'basic', name: 'Basic' },
    { id: 'premium', name: 'Premium' },
    { id: 'enterprise', name: 'Enterprise' },
];

function AddChannelModal({ handleClose, setStatus }) {
    const [message, setMessage] = useState([]);
    const [loading, setLoading] = useState(false);
    const [AssessmentList, SetAssessmentList] = useState([]);
    const [AssessmentSeriesList, setAssessmentSeriesList] = useState([]);
    const [accessOptions, setAccessOptions] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('free');
    const [selectedCurrency, setSelectedCurrency] = useState('usd');
    const [subscriptionAmount, setSubscriptionAmount] = useState(0);
    useEffect(() => {
        async function fetchAccessOptions() {
            const options = await getAccessOptions(); // Call the async function to get options
            setAccessOptions(options); // Set the options in the state
        }

        fetchAccessOptions();
    }, []);


    const formik = useFormik({
        initialValues: {
            channelName: '',
            description: '',
            videoFile: null,
            logoFile: null,
            subscriptionPlan: '',
            Visibility: '',
            assessmentIdList: [],

            SeriesIdList: [],
        },
        onSubmit: values => {
            console.log(values);
            const formData = new FormData();
            formData.append('CreaterID', StanderdConstants.UserId)
            formData.append('ChannelName', values.channelName);
            formData.append('ChannelDescription', values.description);
            formData.append('ChannelLogo', values.logoFile);
            formData.append('ChannelVideo', values.videoFile);
            formData.append('SubscriptionId', values.subscriptionPlan);
            formData.append('Visibility', values.Visibility);
            formData.append('AssessmentIdList', JSON.stringify(values.assessmentIdList));
            formData.append('SeriesIdList', JSON.stringify(values.SeriesIdList));
            formData.append('selectedPlan', selectedPlan)
            formData.append('selectedCurrency', selectedCurrency)
            formData.append('subscriptionAmount', subscriptionAmount)
            saveData(formData);
        }
    });

    console.log(formik.values)
    const saveData = async (data) => {
        try {
            setLoading(true)
            const result = await fetch(`${process.env.REACT_APP_API_URL}/Channel/AddChannel`, {
                method: 'post',
                body: data,
            });
            const response = await result.json();
            if (result.ok) {
                setMessage(response.message);
                setStatus(true);
                setLoading(false)
            }
        } catch (error) {
            setMessage(error.message);
        }
    };

    const handleFileChange = (event, fieldName) => {
        formik.setFieldValue(fieldName, event.currentTarget.files[0]);
    };

    const getAssessments = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessment_get`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: StanderdConstants.UserId })
            });
            const response = await result.json();
            if (result.ok) {
                SetAssessmentList(response.assessments);
            }
        } catch (error) {
            setMessage(error.message);
        }
    };

    useEffect(() => {
        getAssessments();
    }, []);

    const getAssessmentSeriesData = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessmentseries/getseriesNames`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: StanderdConstants.UserId })
            });
            const response = await result.json();
            if (result.ok) {
                setAssessmentSeriesList(response.data);
            }
        } catch (error) {
            setMessage(error.message);
        }
    };

    useEffect(() => {
        getAssessmentSeriesData();
    }, []);

    const combinedList = [
        ...(Array.isArray(AssessmentList) ? AssessmentList.map(assessment => ({
            id: `assessment-${assessment.assessmentId}`,
            name: assessment.nameOfAssessment,
            type: 'assessment'
        })) : []), // Ensure AssessmentList is an array

        ...(Array.isArray(AssessmentSeriesList) ? AssessmentSeriesList.map(series => ({
            id: `series-${series.AssessmentSeriesId}`,
            name: series.AssessmentSeriesName,
            type: 'series'
        })) : []) // Ensure AssessmentSeriesList is an array
    ]


    return (
        <div className='modal-overlayy'>
            <div className='modal-contentt'>
                <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3'>
                    <div className='invisible'>content</div>
                    <Heading heading={"Add Channel"} />
                    <div className='self-center'>
                        <CloseIconButton action={handleClose} />
                    </div>
                </div>

                <form className='flex flex-col gap-10 px-5 mb-5' onSubmit={formik.handleSubmit}>
                    <div className='w-full'>
                        <InputBox
                            inputId='channelName'
                            inputLabel='Channel Name'
                            inputName='channelName'
                            inputValue={formik.values.channelName}
                            onChange={formik.handleChange}
                            customcss='w-full'
                        />
                    </div>
                    <div className='w-full'>
                        <InputBox
                            inputId='description'
                            inputLabel='Description'
                            inputName='description'
                            inputValue={formik.values.description}
                            onChange={formik.handleChange}
                            customcss='w-full'
                            multiline
                            rows={4}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="videoFile">Upload a 30-second Video:</label>
                        <input
                            id="videoFile"
                            name="videoFile"
                            type="file"
                            accept="video/*"
                            onChange={(event) => handleFileChange(event, 'videoFile')}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="logoFile">Upload Logo:</label>
                        <input
                            id="logoFile"
                            name="logoFile"
                            type="file"
                            accept="image/*"
                            onChange={(event) => handleFileChange(event, 'logoFile')}
                        />
                    </div>
                    <div>
                        <CustomSelectv2
                            options={subscriptionPlans}
                            dataField="id"
                            dataText="name"
                            inputId="selectSubscriptionPlan"
                            label="Subscription Plan"
                            name="subscriptionPlan"
                            onChange={(selectedOption) => formik.setFieldValue("subscriptionPlan", selectedOption)}
                            value={formik.values.subscriptionPlan}
                        />
                    </div>

                    <div>
                        <SubscriptionPlanInfo
                            selectedPlan={selectedPlan}
                            setSelectedPlan={setSelectedPlan}
                            selectedCurrency={selectedCurrency}
                            setSelectedCurrency={setSelectedCurrency}
                            subscriptionAmount={subscriptionAmount}
                            setSubscriptionAmount={setSubscriptionAmount}
                        />
                    </div>

                    <div>
                        <CustomSelectv4
                            options={accessOptions}
                            dataField="value"
                            dataText="label"
                            inputId="Visibility"
                            label="Channel Visibility"
                            name="Visibility"
                            onChange={(selectedOption) => formik.setFieldValue("Visibility", selectedOption)}
                            value={formik.values.Visibility}
                        />
                    </div>
                    <div>
                        <InputWithDropDownv2
                            data={combinedList}
                            dataField="id"
                            dataText="name"
                            inputId="selectCombined"
                            inputLabel="Select Assessment or Series"
                            inputName="selectedItem"
                            onChange={(event, value) => {
                                formik.setFieldValue('selectedItem', value ? value.id : '');
                                const selectassessmentList = value.filter(item => item.type == 'assessment')
                                    .map(item => item.id.replace('assessment-', ''));

                                const selectedseriesList = value.filter(item => item.type == 'series')
                                    .map(item => item.id.replace('series-', ''))

                                formik.setFieldValue('assessmentIdList', selectassessmentList)
                                formik.setFieldValue('SeriesIdList', selectedseriesList)
                                formik.setFieldValue('selectedassessmentData', value.map(item => item.id))

                            }}
                            inputValue={combinedList.filter(item =>
                                (formik.values.selectedassessmentData || []).includes(item.id)
                            ) || []} />
                    </div>
                    {message && <p>{message}</p>}

                    <Button variant='contained' className='mt-4' id='addChannel' type='submit'>
                        Save
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default AddChannelModal;
