import { Button } from '@mui/material';
import { convertFromRaw, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Heading from '../../reusableComp/Heading';
import { CustomButton } from '../../../ButtonsIconsInputs/IconButtons';

function SaveExamData() {
    const [assessmentAttemptId, setassessmentAttempId] = useState()
    const location = useLocation()
    const navigate = useNavigate();
    const [Error, SetError] = useState(false)
    const [instructions, setInstructions] = useState('')
    const data = location.state?.data;
    console.log(data)

    const token = localStorage.getItem('token')
    const decodedToken = jwtDecode(token);
    const userId = decodedToken.userId;
    console.log(userId)
    const assessmentAttemptData = {
        assessmentId: data,
        UserId: userId,
        assessmentDateTime: new Date(),

    }
    const GenerateAssessment = async () => {

        const response = await fetch(`${process.env.REACT_APP_API_URL}/assessmentAttempt/save`, {
            method: 'post',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(assessmentAttemptData)
        });
        const result = await response.json();
        console.log(result)



        if (!response.ok) {
            console.error("Error occurred: " + result.message);
            SetError(result.message)

        }
        else {
            setassessmentAttempId(result.assessmentAttemptId)
            const rawContent = JSON.parse(result.instructions);
            const contentState = convertFromRaw(rawContent);
            const Editorstatedata = EditorState.createWithContent(contentState)
            const htmlContent = stateToHTML(Editorstatedata.getCurrentContent());

            setInstructions(htmlContent)



        }
    };
    useEffect(() => {
        GenerateAssessment()
    }, [])

    const ToExam = () => {


        navigate(`/exam/`, { state: { data: assessmentAttemptId, assessmentId: assessmentAttemptData.assessmentId } })


    }

    return (

        <div className="flex  flex-col items-center my-4 justify-center min-h-screen bg-white">

            {Error ? (
                <div className='flex justify-center items-center w-full h-full'>
                    <div className="bg-red-100 text-red-800 border border-red-400 p-4 rounded-md">
                        <strong>Something went wrong!</strong> Please try again.
                    </div>
                </div>
            ) : (
                <>
                    {!instructions ? (
                        <div className="flex items-center justify-center w-full h-screen">
                            <div className="text-center text-gray-700">
                                <h2 className="text-2xl font-semibold">Loading...</h2>
                                <p>Please wait while we fetch the instructions.</p>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col items-center w-5/6  border-gray-300    rounded-md shadow-lg ">
                            <div className='bg-sky-600 w-full text-gray-50 rounded-t-md'><Heading heading='Exam Instructions' customecss='' />
                            </div>

                            <div className="assessment-instructions w-full ">
                                <div
                                    className="  rounded-md p-10 "
                                    dangerouslySetInnerHTML={{ __html: instructions }}
                                />
                            </div>



                        </div>


                    )}

                </>
            )}
            {instructions && <CustomButton
                variant="contained"
                customcss='w-5/6 my-2'
                onClick={ToExam}
                Text='Start Assessment'
            />}

        </div>

    )
}

export default SaveExamData
