import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddIconButton, EditIconButton, TakeExamIconButtom, ViewIconButton } from '../../ButtonsIconsInputs/IconButtons';
import Heading from '../reusableComp/Heading';
import DeleteConfirmation from '../reusableComp/Deletecomfirmation';
import TableComp from '../reusableComp/TableComp';
import { Button, TextField, Pagination, Autocomplete, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import { hasPermission } from '../reusableComp/PermissionCheck';

function ManageQuestions() {
    const permissionsNameForThisPage = {
        Add: 'Question-Add',
        Edit: 'Question-Edit',
        Delete: 'Question-Delete',

    }
    const [questions, setQuestions] = useState([]);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1); // State for pagination page number
    const [totalPages, setTotalPages] = useState(1); // State for total pages
    const navigate = useNavigate();

    const getdata = async (data, page) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/questions_answers/getQuestions?page=${page}`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            });
            const response = await result.json();
            setQuestions(response.data); // Assuming response contains 'questions' and 'totalPages'
            setTotalPages(response.totalPages);

        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        getdata(formik.values, page);
    }, [page]);

    const previewAssessment = (assessmentId) => {
        navigate('/previewQuestion', { state: { data: assessmentId } });
    };



    const DeleteQuestion = async (QuestionId) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/questions_answers/delete`, {
                method: 'delete',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ questionId: QuestionId })
            });
            if (result.ok) {
                getdata(formik.values, page);
            } else {
                console.log(await result.json())
            }
        } catch (error) {
            setError(error.message);
        }
    };

    const columns = [
        { header: 'Question', field: 'question' },
        { header: 'Skill ', field: 'skillName' },
        { header: 'Question Type', field: 'questionType' },
        { header: 'Difficulty Level', field: 'difficulty' },
    ];

    const openEditComp = (item) => {
        navigate('/editQuestion', { state: { data: item } });
    };

    const actions = [
        {
            label: 'Edit',
            handler: (item) => openEditComp(item),
            tooltip: 'Edit item',
            ButtonComponent: EditIconButton,
            requiredPermission: permissionsNameForThisPage.Edit,

        },
        {
            label: 'Delete',
            handler: (item) => DeleteQuestion(item.questionId),
            tooltip: 'Delete item',
            ButtonComponent: DeleteConfirmation,
            requiredPermission: permissionsNameForThisPage.Delete,

        }

    ];

    const formik = useFormik({
        initialValues: {
            page: 1, // Start from page 1
            skillFilter: '',
            difficultyFilter: '',
            questionTypeFilter: '',
            questionTextFilter: ''
        },
        onSubmit: values => {
            setPage(1);
            getdata(values, 1);
        },
        onReset: value => {
            getdata()
        }
    });

    const handlePageChange = (page) => {
        setPage(page)
        formik.setFieldValue('page', page);

    };
    const questionTypes = [
        { value: 'free', label: 'Free Text' },
        { value: 'mcq-single', label: 'MCQ Single' },
        { value: 'mcq-multiple', label: 'MCQ Multiple' },
    ];
    const difficultyLevels = [
        { value: 'free', label: 'easy' },
        { value: 'normal', label: 'normal' },
        { value: 'hard', label: 'hard' }
    ]
    return (
        <div className="container mx-auto p-4">
            {error ? (
                <p className="text-red-500">Error: {error}</p>
            ) : (
                <>
                    <Heading heading={"Manage Question"} />
                    <div className='flex flex-col gap-2'>
                        <div className='flex justify-end'>
                            {hasPermission(permissionsNameForThisPage.Add) && <AddIconButton
                                action={() => { navigate('/createQuestion') }}
                                tooltip='Create Quesiton'
                            />}
                        </div>

                        <form className='flex flex-col md:flex-row lg:flex-row  justify-between gap-3 mb-5' onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                            <TextField id="questionText" name='questionTextFilter' onChange={formik.handleChange} value={formik.values.questionTextFilter} label="Question" variant="standard" />
                            <TextField id="skill" name='skillFilter' onChange={formik.handleChange} value={formik.values.skillFilter} label="Skill" variant="standard" />
                            <TextField
                                id="questionType"
                                name="questionTypeFilter"
                                select
                                value={formik.values.questionTypeFilter}
                                label="Question Type"
                                onChange={formik.handleChange}
                                variant="standard"
                                className='w-full md:w-1/6 lg:w-1/6'
                                slotProps={{
                                    select: {
                                        native: true,
                                    },
                                }}
                            >
                                {questionTypes.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField id="difficulty" className='w-full md:w-1/6 lg:w-1/6' select name='difficultyFilter' onChange={formik.handleChange} value={formik.values.difficultyFilter} label="Difficulty Level" variant="standard"
                            >
                                {difficultyLevels.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Button id='findQuestion' type='submit' variant='outlined'>Find</Button>
                            <Button id='ResetSearch' variant='outlined' type='reset'>Reset</Button>
                        </form>
                    </div>
                    <TableComp
                        data={questions}
                        columns={columns}
                        actions={actions}
                    />
                    <div className='flex justify-center'>
                        <Pagination
                            count={totalPages}
                            page={page}
                            onChange={(event, value) => handlePageChange(value)}
                            color="primary"
                        />
                    </div>
                </>
            )}
        </div>
    );
}

export default ManageQuestions;
