import React, { useEffect, useState } from 'react';
import StanderdConstants from '../../services/StanderdConstants';

export default function Subscription({ userId = StanderdConstants.UserId, Type, Id, SubscriptionPlanTypeId, SubscriptionAmount }) {
    const [loading, setLoading] = useState(false); // Track the loading state
    const [subscribed, setSubscribed] = useState(false); // Track if the user has subscribed
    const [error, setError] = useState(null); // Track any errors
    console.log(Type, Id, SubscriptionPlanTypeId, SubscriptionAmount)
    // Subscription handler function
    const handleSubscription = async () => {
        setLoading(true);
        setError(null);

        try {
            // Call the subscription API using fetch
            const response = await fetch(`${process.env.REACT_APP_API_URL}/Subscription/Subscribe/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId,
                    Type, // 'assessment', 'series', or 'channel'
                    Id, // AssessmentId, SeriesId, or ChannelId
                    SubscriptionPlanTypeId,
                    SubscriptionAmount
                }),
            });

            // Handle the response
            if (!response.ok) {
                throw new Error(`Failed to subscribe. Status: ${response.status}`);
            }

            const result = await response.json();
            console.log('Subscription successful:', result);

            // Update the state to show that the user has subscribed
            setSubscribed(true);
        } catch (error) {
            console.error('Error during subscription:', error);
            setError('Failed to subscribe, please try again.');
        } finally {
            setLoading(false); // Reset loading state
        }
    };



    // Button text based on SubscriptionAmount
    let buttonText = SubscriptionAmount == 0 ? 'Subscribe' : `Subscribe for $${SubscriptionAmount}`;
    useEffect(() => {
        if (subscribed) {
            buttonText = 'Subscribed'
        }
    }, [subscribed])
    return (
        <div>
            {/* Show success or error messages */}


            {/* Disable button while loading */}
            <button
                onClick={handleSubscription}
                className={`px-6 py-2 mt-4 border-none text-white font-bold rounded ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'
                    }`}
                disabled={loading}
            >
                {loading ? 'Processing...' : buttonText}
            </button>
        </div>
    );
}
